import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { JobDetail } from '../../../core';
import { getJobStatus } from '../getJobStatus';

type JobTitleProps = {
	job: JobDetail;
	children: ReactNode;
};

export const JobTitle = ({ job, children }: JobTitleProps) => {
	const theme = useTheme();
	const status = getJobStatus(job);

	if (!job || !status) return null;
	const repairProgram =
		job.RepairProgramName === 'None' ? '' : job.RepairProgramName || '';

	return (
		<ThemeProvider theme={status.theme}>
			<AppBar
				position="relative"
				sx={{
					zIndex: theme.zIndex.drawer + 1,
					'& h6': {
						marginRight: theme.spacing(2),
					},
					transition: theme.transitions.create(['margin', 'width'], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
				}}
				color="primary"
				>
				<Toolbar disableGutters sx={{pl: theme.spacing(2)}}>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						pr: theme.spacing(2)
					}}>
						<Box sx={{ fontSize: theme.typography.h6.fontSize }}>
							<strong>{job?.JobNumber}</strong>
							<Hidden xsDown> - {job?.JobName}</Hidden>
							{repairProgram && (
								<Hidden mdDown> - {repairProgram}</Hidden>
							)}
						</Box>
						<Hidden xsDown>
							<Box sx={{ fontSize: theme.typography.body2.fontSize }}>
								<strong>{status.phase}</strong> -{' '}
								{status.status}
							</Box>
						</Hidden>
					</Box>
					{children}
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
};
