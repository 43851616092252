import Icon from '@mdi/react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useAppContext } from '../../contexts/AppContext';
import { BottomSpace } from '../common';
import { createNavigation } from './utils';
import { ListItemButton } from '@mui/material';

type SidebarIconsProps = {
	onNavigateKey: (key: string) => void;
	onClose: () => void;
};

export const SidebarNavigation = ({
	onNavigateKey,
	onClose,
}: SidebarIconsProps) => {
	const { hasPermission } = useAppContext();

	const [sidebarItems, sidebarBottomItems] = createNavigation(hasPermission);

	return (
		<List>
			{sidebarItems.map((item) => (
				<ListItemButton key={item.key} onClick={() => onNavigateKey(item.key)} sx={theme => ({ pl: theme.spacing(2)})}>
					<ListItem>
						<ListItemIcon>
							<Icon path={item.icon} size={1} color="#555555" />
						</ListItemIcon>
						<ListItemText primary={item.key} />
					</ListItem>
				</ListItemButton>
			))}
			{sidebarBottomItems.map((item) => (
				<ListItemButton key={item.key} onClick={() => onNavigateKey(item.key)} sx={theme => ({ pl: theme.spacing(2)})}>
					<ListItem>
						<ListItemIcon>
							<Icon path={item.icon} size={1} color="#555555" />
						</ListItemIcon>
						<ListItemText primary={item.key} />
					</ListItem>
				</ListItemButton>
			))}
			<BottomSpace />
		</List>
	);
};
