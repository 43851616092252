import { createTimestamp } from "./createTimestamp"

export const deleteAfterDays = (days: number, from?: string | Date | number | null | undefined): number => {
    return createTimestamp(60 * 24 * days, from);
}

export const deleteAfterMinutes = (minutes: number, from?: string | Date | number | null | undefined): number => {
    return createTimestamp(60 * minutes, from);
}

export const deleteAfterSeconds = (seconds: number, from?: string | Date | number | null | undefined): number => {
    return createTimestamp(seconds, from);
}