/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./types";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createTriggers = /* GraphQL */ `mutation CreateTriggers(
  $input: CreateTriggersInput!
  $condition: ModelTriggersConditionInput
) {
  createTriggers(input: $input, condition: $condition) {
    id
    type
    status
    progress
    createdAt
    updatedAt
    deleteAfter
    payload
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTriggersMutationVariables,
  APITypes.CreateTriggersMutation
>;
export const updateTriggers = /* GraphQL */ `mutation UpdateTriggers(
  $input: UpdateTriggersInput!
  $condition: ModelTriggersConditionInput
) {
  updateTriggers(input: $input, condition: $condition) {
    id
    type
    status
    progress
    createdAt
    updatedAt
    deleteAfter
    payload
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTriggersMutationVariables,
  APITypes.UpdateTriggersMutation
>;
export const deleteTriggers = /* GraphQL */ `mutation DeleteTriggers(
  $input: DeleteTriggersInput!
  $condition: ModelTriggersConditionInput
) {
  deleteTriggers(input: $input, condition: $condition) {
    id
    type
    status
    progress
    createdAt
    updatedAt
    deleteAfter
    payload
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTriggersMutationVariables,
  APITypes.DeleteTriggersMutation
>;
