import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {
	JobContactData,
	JobSiteContactData,
	LookupTypes,
	validatePhoneNumber,
} from '../../../core';
import {
	Dialog,
	Container,
	AutoCompleteLookup,
	Loading,
	ErrorMessage,
} from '../../common';
import { useLookupTypes } from '../../lookups';
import { useAppContext } from '../../../contexts/AppContext';

type State = {
	working: boolean;
	error?: any;
	data: Partial<JobSiteContactData>;
};

export type Props = {
	jobId: number;
	contact: JobContactData;
	onJobsiteContactRemoved: (jobSiteContact: JobSiteContactData) => void;
	onJobsiteContactUpdated: (jobSiteContact: JobSiteContactData) => void;
	onClose: () => void;
};

export const EditJobsiteContactDialog = ({
	jobId,
	contact,
	onClose,
	onJobsiteContactRemoved,
	onJobsiteContactUpdated,
}: Props) => {
	const { api } = useAppContext();
	const [contactTypes] = useLookupTypes([LookupTypes.JobsiteContactType]);
	const defaultJobSiteContactTypeId =
		contactTypes?.find((x: any) => x.Name === 'Maintenance')?.ID ||
		undefined;

	const [state, setState] = React.useState<State>({
		working: false,
		data: {
			JobID: jobId,
			ContactID: contact.ContactID,
			JobsiteContactName: contact.ContactName,
			JobsiteContactTypeID: defaultJobSiteContactTypeId,
			PhoneNumber: contact.PhoneNumber || undefined,
			Description: contact.Description,
			JobsiteContactID: contact.ID,
		},
	});

	const handleChange = (property: string, value: any) => {
		setState((s) => ({ ...s, data: { ...s.data, [property]: value } }));
	};

	const updateJobsiteContact = async () => {
		const d = state.data;
		if (d.JobID && d.JobsiteContactID) {
			setState((s) => ({ ...s, working: true, error: undefined }));
			try {
				const updated = await api.job.updateJobsiteContact({
					JobID: d.JobID,
					JobsiteContactID: d.JobsiteContactID,
					ContactID: d.ContactID,
					Description: d.Description,
					JobsiteContactName: d.JobsiteContactName,
					PhoneNumber: d.PhoneNumber,
				});
				onJobsiteContactUpdated(updated);
				// setState((s) => ({ ...s, working: false }));
			} catch (error) {
				setState((s) => ({ ...s, working: false, error }));
			}
		}
	};

	const handleRemoveContact = async () => {
		const d = state.data;
		if (d.JobID && d.JobsiteContactID) {
			try {
				await api.job.removeJobsiteContact(d.JobID, d.JobsiteContactID);
				onJobsiteContactRemoved(d as JobSiteContactData);
			} catch (error) {
				console.error(error);
			}
		}
	};

	const isContactValid = !!state.data.JobsiteContactTypeID;

	return (
		<Dialog
			isOpen={true}
			title={`Edit Jobsite Contact: ${contact.ContactName}`}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
				isDisabled: () => state.working,
			}}
			positiveAction={{
				text: 'Update Contact',
				isDisabled: () =>
					state.working || !!state.error || !isContactValid,
				onClick: updateJobsiteContact,
			}}
			negativeAction={{
				text: 'Remove Contact',
				isDisabled: () => state.working || !!state.error,
				onClick: handleRemoveContact,
			}}
			onClose={onClose}
		>
			<Container
				component="AddJobContact"
				scrollable
				sx={theme => ({ padding: theme.spacing(0, 2, 2, 2) })}
			>
				<ErrorMessage
					error={state.error}
					onRetry={() =>
						setState((s) => ({ ...s, error: undefined }))
					}
				/>
				{state.working && <Loading />}
				<Grid container direction="column">
					<Grid item sx={theme => ({ px: theme.spacing(2), mb: theme.spacing(2) })}>
						<Grid item style={{ flexGrow: 1 }}>
							<TextField
								label="Name"
								aria-label="Name"
								title="Name"
								value={state.data.JobsiteContactName || ''}
								onChange={(e) =>
									handleChange(
										'JobsiteContactName',
										e.target.value
									)
								}
								onFocus={(e) => e.target.select()}
								variant="outlined"
								margin="dense"
								fullWidth
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
					</Grid>
					<Grid item sx={theme => ({ px: theme.spacing(2), mb: theme.spacing(2) })}>
						<AutoCompleteLookup
							lookupType={LookupTypes.JobsiteContactType}
							label="Type"
							placeholder="Type"
							required
							value={state.data.JobsiteContactTypeID || null}
							onChange={(value) =>
								handleChange('JobsiteContactTypeID', value)
							}
							error={!state.data.JobsiteContactTypeID}
						/>
					</Grid>
					<Grid item sx={theme => ({ px: theme.spacing(2), mb: theme.spacing(2) })}>
						<TextField
							aria-label="Phone Number"
							title="Phone Number"
							label="Phone Number"
							value={state.data.PhoneNumber || ''}
							onChange={(e) =>
								handleChange('PhoneNumber', e.target.value)
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							required
							fullWidth
							InputLabelProps={{ shrink: true }}
							error={!validatePhoneNumber(state.data.PhoneNumber)}
						/>
					</Grid>
					<Grid item sx={theme => ({ px: theme.spacing(2), mb: theme.spacing(2) })}>
						<TextField
							aria-label="Description"
							title="Description"
							label="Description"
							value={state.data.Description || ''}
							onChange={(e) =>
								handleChange('Description', e.target.value)
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							multiline
							fullWidth
							minRows={4}
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>
				</Grid>
			</Container>
		</Dialog>
	);
};
