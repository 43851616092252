import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { dateIsValid } from '../../core';

type DatePickerProps = {
	label?: string;
	value?: string | null;
	onChange: (value?: string | null) => void;
	style?: React.CSSProperties;
	className?: string;
	error?: boolean;
	disabled?: boolean;
	required?: boolean;
};

export const DatePicker = (props: DatePickerProps) => {
	const {
		label,
		value,
		onChange,
		className,
		style = {},
		disabled = false,
	} = props;

	return (
		<MuiDatePicker
			formatDensity='dense'
			format="MM/dd/yyyy"
			label={label}
			value={value ? new Date(value) : null}
			onChange={(value) => {
				if (value && dateIsValid(value)) {
					onChange(value.toISOString());
				} else {
					onChange(null);
				}
			}}
			disabled={disabled}
			className={className}
			sx={style}
		/>
	);
};
