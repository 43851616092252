import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { api } from '../api';
import { User, UserRolePermission } from '../core';
import { Authenticate } from '../features/auth/Authenticate';
import { loadEmployeeData } from '../features/employees';
import { loadLookupData } from '../features/lookups';
import { useAppDispatch } from '../store';
import { loadAccountingReports, loadMainReports } from "../store/reports";
import { CognitoAuthProvider } from './CognitoAuthProvider';
import { loadTaskTemplates } from '../store/taskTemplates';
import { loadWatchListItems } from '../store/watchlist';

const unAuthUser: User = {
	UserID: '',
	EmployeeID: 0,
	EmployeeNumber: '',
	isActive: false,
	EmployeeName: '',
	usr1: false,
	usr2: false,
	usr3: false,
	usr4: false,
	usr5: false,
	usr6: false,
	usr7: false,
	usr8: false,
	usr9: false,
	UserRoleID: 0,
	UserRoleName: '',
};

interface AppState {
	username?: string;
	authId?: string;
	user: User;
	permissions: UserRolePermission[];
}

export interface ApplicationContextData extends AppState {
	api: typeof api;
	region: string;
	bucket: string;
	signin: (username: string, password: string) => Promise<void>;
	signout: () => void;
	isMobileLayout: boolean;
	isWideLayout: boolean;
	isNarrowLayout: boolean;
	hasPermission: (permissions: string[]) => boolean;
}

const initialAppState: AppState = {
	user: unAuthUser,
	permissions: [],
};

const initialValue: ApplicationContextData = {
	...initialAppState,
	region: '',
	bucket: '',
	api,
	signin: () => Promise.resolve(),
	signout: () => { },
	isMobileLayout: true,
	isWideLayout: false,
	isNarrowLayout: false,
	hasPermission: () => false,
};

export const ApplicationContext =
	React.createContext<ApplicationContextData>(initialValue);

export interface AppContextProps {
	region: string;
	bucket: string;
	children: React.ReactNode;
}

const cognito = new CognitoAuthProvider();

export const AppContext = (props: AppContextProps) => {
	const { children } = props;
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const isMobileLayout = useMediaQuery(theme.breakpoints.down('md'));
	const isWideLayout = useMediaQuery(theme.breakpoints.up('md'));
	const isNarrowLayout = useMediaQuery(theme.breakpoints.down('sm'));
	const [state, setState] = useState<AppState>(initialAppState);
	const [loading, setLoading] = useState<boolean>(false);
	const [message, setMessage] = useState<string>();

	// console.log('AppContext State', state);

	const hasPermission = React.useCallback(
		(values: string[]): boolean => {
			if (!state.permissions || state.permissions.length === 0) {
				return false;
			}
			if (
				state.permissions.find((x) => x.PermissionName === 'SYSADMIN')
			) {
				return true;
			}
			const ps = values.map((x) => x.toLowerCase());
			const permission = state.permissions.find((x) =>
				ps.includes(x.PermissionName.toLowerCase())
			);
			return !!permission;
		},
		[state.permissions]
	);

	const loadProfile = useCallback(
		async (username: string, authId: string) => {
			setLoading(true);
			try {
				const [user, permissions, lookups, employees, accounting, main, taskTemplates] =
					await Promise.all([
						api.user.get(),
						api.permission.query(),
						api.lookup.query(),
						api.employee.query(),
						api.reports.query('acc'),
						api.reports.query('main'),
						api.taskTemplates.query(),
					]);
				setState((s) => ({
					...s,
					user,
					permissions,
					username,
					authId,
				}));
				
				const watchListItems = await api.employee.queryJobWatchList(user.EmployeeNumber);

				dispatch(loadLookupData(lookups));
				dispatch(loadEmployeeData(employees));
				dispatch(loadAccountingReports(accounting));
				dispatch(loadMainReports(main));
				dispatch(loadTaskTemplates(taskTemplates))
				dispatch(loadWatchListItems(watchListItems));
			} catch (error) {
				setState((s) => ({
					...s,
					user: unAuthUser,
					permissions: [],
					authId: undefined,
					username: undefined,
				}));
				setMessage(`${error}`);
			} finally {
				setLoading(false);
			}
		},
		[dispatch]
	);

	const signin = async (userId: string, password: string) => {
		setLoading(true);
		try {
			await cognito.signOut();
			const user = await cognito.signIn(userId, password);
			return loadProfile(user.username, user.sub);
		} catch (error) {
			console.error(error);
			setMessage(`${error}`);
			setLoading(false);
		}
	};

	const signout = async () => {
		await cognito.signOut();
		setLoading(false);
		setState({ ...initialAppState });
		navigate('/');
	};

	const isAlreadyLoggedIn = useCallback(async () => {
		try {
			const user = await cognito.getUser();
			return loadProfile(user.username, user.sub);
		} catch (error) {
			// DO NOTHING
		}
	}, [loadProfile]);

	useEffectOnce(() => {
		isAlreadyLoggedIn();
	});

	const showChildren =
		!loading && !!state.authId && state.user.EmployeeID !== 0;

	return (
		<ApplicationContext.Provider
			value={{
				...state,
				api,
				signin,
				signout,
				isMobileLayout,
				isWideLayout,
				isNarrowLayout,
				hasPermission,
				region: props.region,
				bucket: props.bucket
			}}
		>
			<Authenticate
				signin={signin}
				message={message}
				busy={loading}
				show={!showChildren}
			/>
			{!!showChildren && <>{children}</>}
		</ApplicationContext.Provider>
	);
};

export const useAppContext = (): ApplicationContextData => {
	return useContext(ApplicationContext);
};
