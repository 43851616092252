// Lib
import { isArray, replace } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const uniqueId = (): string => replace(uuidv4().toLowerCase(), '-', '');	

export const hasNumericValue = (value?: number | null | undefined): boolean => {
	return value !== null && value !== undefined && value !== -1;
};

// export const formatDate = (value: Date): string => {
// 	if (!value) return '';
// 	let dt: any;
// 	if (!isDate(value)) {
// 		dt = new Date(value);
// 	}
// 	let hours = dt.getHours();
// 	const minutes = dt.getMinutes();
// 	const amPm = hours >= 12 ? 'pm' : 'am';
// 	hours %= 12;
// 	hours = hours || 12; // the hour '0' should be '12'
// 	const strMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
// 	const strTime = `${hours}:${strMinutes} ${amPm}`;
// 	return `${
// 		dt.getMonth() + 1
// 	}/${dt.getDate()}/${dt.getFullYear()} ${strTime}`;
// };

export const truncate = (value: string | null | undefined, limit: number) => {
	if (!value) return '';
	return value.length > limit ? `${value.substr(0, limit)}...` : value;
};

// export const formatDateTime = (val?: string | null): string => {
// 	if (!val) return '';
// 	try {
// 		const dt = new Date(val);
// 		return dt.toLocaleTimeString('en-US', {
// 			hour: 'numeric',
// 			minute: 'numeric',
// 		});
// 	} catch (error) {
// 		return '';
// 	}
// };

export const removePunctuation = (input?: string) => {
	if (!input) return '';
	const val = input.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');
	return val.replace(/\s{2,}/g, ' ');
};

export const removeNonAlphaNumeric = (input?: string, replacement: string = '') => {
	if (!input) return '';
	const val = input.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, replacement);
	return val.replace(/\s+/g, replacement);
};

export const cleanPhoneNumber = (input?: string): string => {
	const val = removePunctuation(input);
	return replace(val, ' ', '');
};

export const formatPhoneNumber = (input?: string) => {
	if (!input) return '';
	const val = removePunctuation(input);
	const line: string[] = [];
	const prefix: string[] = [];
	const areaCode: string[] = [];
	const country: string[] = [];
	let count = 1;
	const str = val.split('').reverse();
	str.forEach((d) => {
		if (count <= 4) line.splice(0, 0, d);
		if (count > 4 && count < 8) prefix.splice(0, 0, d);
		if (count > 7 && count < 11) areaCode.splice(0, 0, d);
		if (count > 10) country.splice(0, 0, d);
		count += 1;
	});
	let phone = '';
	if (line.length) phone = line.join('');
	if (prefix.length) phone = `${prefix.join('')}-${phone}`;
	if (areaCode.length) phone = `(${areaCode.join('')}) ${phone}`;
	if (country.length) phone = `+${country.join('')} ${phone}`;
	return phone;
};

export const stringifySearchOptions = (options: any): string => {
	const items: string[] = [];
	Object.keys(options).forEach((key) => {
		const val = (options as any)[key];
		if (val !== undefined && val !== null) {
			if (isArray(val)) {
				items.push(`${key}=${val.join(',')}`);
			} else {
				items.push(`${key}=${val}`);
			}
		}
	});
	return items.join('&');
};

export const tryParseFloat = (value: any) => {
	if (!value) return 0;
	try {
		return parseFloat(value);
	} catch (error) {
		return 0;
	}
};

export const formatDecimal = (value: number | null | undefined): string => {
	if (value === null || value === undefined) {
		return '0';
	}

	const result = new Intl.NumberFormat('en-US', {
		style: 'decimal',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(value);

	return result;
};

export const formatAmount = (value: number): string =>
	new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	}).format(value);

export const formatCommission = (value: number): string =>
	new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 5,
		maximumFractionDigits: 5,
	})
		.format(value)
		.replace('$', '');

export const formatZipCode = (input?: string) => {
	if (!input) return '';
	const val = removePunctuation(input);
	return val.substring(0, 5);
};
