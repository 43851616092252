import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useS3FileBrowserContext } from './S3FileBrowserContext';
import { Upload } from './Upload';
import { S3Object } from './types';

type Props = {
	folder: S3Object;
	onClose: () => void;
};

export const UploadDialog = ({ folder, onClose }: Props) => {
	const { reload } = useS3FileBrowserContext();

	const handleUploadComplete = () => {
		onClose();
		setTimeout(() => {
			reload();
		}, 500);
	};

	return (
		<Dialog fullScreen title="Upload" open={true} onClose={onClose}>
			<AppBar position="relative">
				<Toolbar variant="dense">
					<span style={{ flexGrow: 1, fontWeight: 500 }}>Upload</span>
					<IconButton
						size="small"
						edge="end"
						aria-label="Close"
						onClick={onClose}
					>
						<Icon path={mdiClose} size={1} color="#fff" />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				width: '100%',
				padding: 0,
				margin: 0,
			}}>
				<Upload
					folder={folder.key}
					onUploadComplete={handleUploadComplete}
					onError={console.error}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={onClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};
