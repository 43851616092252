import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, ListItemButton } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { useDebounce } from 'react-use';
import { JobSearchOptions, LookupTypes } from '../../core';
import { AutoCompleteLookup, EmployeeDropDown, FlexRow } from '../common';
import { FilterMenuItem, JobListJobStatus } from './JobListJobStatus';
import { useState } from 'react';

const UserFilterItems: FilterMenuItem[] = [
	{ key: 'usr1', label: 'EST' },
	{ key: 'usr2', label: 'PM' },
	{ key: 'usr3', label: 'LEVEL5' },
	{ key: 'usr4', label: 'LEAD' },
	{ key: 'usr5', label: 'ADMIN' },
	{ key: 'usr6', label: 'ACCT' },
	{ key: 'usr7', label: 'DRM' },
	{ key: 'usr8', label: 'OPEN' },
	{ key: 'usr9', label: 'MKTING' },
];

const UserKeys = UserFilterItems.map((x) => x.key);

const filterMenuItems = (
	options: JobSearchOptions,
	menuItems: FilterMenuItem[]
): FilterMenuItem[] => {
	const optionKeys = Object.keys(options);
	const userKeys = UserFilterItems.map((x) => x.key);

	const items = menuItems.filter((x) => !optionKeys.includes(x.key));
	if (!!optionKeys.find((x) => userKeys.includes(x))) {
		return items.filter((x) => !userKeys.includes(x.key));
	} else {
		return items;
	}
};

type Props = {
	busy: boolean;
	options: JobSearchOptions;
	onFilterChange: (options: JobSearchOptions) => void;
};

export function JobsListFilter({ onFilterChange, options, busy }: Props) {
	const theme = useTheme();
	const [search, setSearch] = useState(options.search || '');

	// Filters
	const handleAddFilter = (key: string) => {
		console.log('handleAddFilter', key);
		onFilterChange({ ...options, [key]: null });
	};

	const handleRemoveFilter = (key: string) => {
		const opts = { ...options };
		delete (opts as any)[key];
		onFilterChange(opts);
	};

	const handleSetFilter = (key: string, value: any) => {
		onFilterChange({ ...options, [key]: value || undefined });
	};

	const handleSetWatchList = (enabled: boolean) => {
		onFilterChange({ ...options, onlyWatchList: enabled === true });
	};

	useDebounce(
		() => {
			const opts = { ...options };
			if (!search) {
				delete opts.search;
			} else {
				opts.search = search;
			}
			onFilterChange(opts);
		},
		1000,
		[search]
	);

	const selectedUserKey = Object.keys(options).find((x) =>
		UserKeys.includes(x)
	);
	const selectedUserLabel =
		UserFilterItems.find((x) => x.key === selectedUserKey)?.label ||
		'Error';

	// console.log('options', options);
	// console.log('selectedUserKey', selectedUserKey);
	// console.log('selectedUserLabel', selectedUserLabel);

	return (
		<Box
			data-component="job-list-filters"
			style={{
				display: 'flex',
				flexDirection: 'column',
				padding: theme.spacing(1),
			}}
		>
			<List disablePadding sx={{ mb: theme.spacing(1) }}>
				<ListItemButton
					onClick={() =>
						handleSetFilter(
							'scope',
							options.scope === 'all' ? 'open' : 'all'
						)
					}
				>
					<ListItem
						disableGutters
						sx={{ paddingLeft: theme.spacing(1) }}
					>
						<ListItemText
							primary={
								options.scope === 'all'
									? 'All Jobs'
									: 'My Open Jobs'
							}
						/>
						<Switch
							edge="end"
							checked={options.scope === 'all'}
							onChange={(_e, checked) =>
								handleSetFilter('scope', checked ? 'all' : 'open')
							}
							name="checkedA"
							inputProps={{ 'aria-label': 'job scope' }}
							disabled={busy}
						/>
					</ListItem>
				</ListItemButton>
				<ListItemButton onClick={() => handleSetWatchList(!options.onlyWatchList)}>
					<ListItem
						disableGutters
						sx={{ paddingLeft: theme.spacing(1) }}
					>
						<ListItemText primary="Watch List" />
						<Switch
							edge="end"
							checked={options.onlyWatchList === true}
							onChange={(_e, checked) => handleSetWatchList(checked)}
							name="checkedWL"
							inputProps={{ 'aria-label': 'watch list' }}
							disabled={busy}
						/>
					</ListItem>
				</ListItemButton>
			</List>
			<JobListJobStatus
				label="Job Status"
				placeholder="Job Status"
				value={options.status || null}
				disabled={busy}
				onChange={(value) =>
					handleSetFilter('status', value || undefined)
				}
				onMenuItemClick={handleAddFilter}
				menuFilter={(items) => filterMenuItems(options, items)}
			/>
			{!!selectedUserKey && (
				<FlexRow
					nowrap
					style={{ alignItems: 'center', marginTop: '0.5rem' }}
				>
					<EmployeeDropDown
						label={selectedUserLabel}
						placeholder={selectedUserLabel}
						value={(options as any)[selectedUserKey] || null}
						onChange={(employeeNumber) =>
							handleSetFilter(
								selectedUserKey,
								employeeNumber || undefined
							)
						}
						error={!(options as any)[selectedUserKey]}
						filter={(employees) =>
							employees.filter(
								(x) => x.isActive && (x as any)[selectedUserKey]
							)
						}
					/>
					<div style={{ marginLeft: '8px' }}>
						<Tooltip title="Remove Filter">
							<IconButton
								aria-label="remove filter"
								size="small"
								onClick={() =>
									handleRemoveFilter(selectedUserKey)
								}
							>
								<Icon path={mdiClose} size={1} />
							</IconButton>
						</Tooltip>
					</div>
				</FlexRow>
			)}
			{Object.keys(options).includes('division') && (
				<FlexRow
					nowrap
					style={{ alignItems: 'center', marginTop: '0.5rem' }}
				>
					<AutoCompleteLookup
						lookupType={LookupTypes.Division}
						label="Division"
						placeholder="Division"
						required
						value={options.division || null}
						onChange={(value) =>
							handleSetFilter('division', value || undefined)
						}
						error={!options.division}
					/>
					<div style={{ marginLeft: '8px' }}>
						<Tooltip title="Remove Filter">
							<IconButton
								aria-label="remove filter"
								size="small"
								onClick={() => handleRemoveFilter('division')}
							>
								<Icon path={mdiClose} size={1} />
							</IconButton>
						</Tooltip>
					</div>
				</FlexRow>
			)}
			<Box sx={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
				<TextField
					label="Search"
					placeholder="Search name, number, address, claim"
					variant="outlined"
					margin="dense"
					fullWidth
					disabled={busy}
					value={search || ''}
					onChange={(e) => setSearch(e.currentTarget.value)}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						endAdornment: (
							<>
								{!!options.search && (
									<InputAdornment position="end">
										<IconButton
											aria-label="add filter"
											size="small"
											onClick={() => setSearch('')}
										>
											<Icon path={mdiClose} size={1} />
										</IconButton>
									</InputAdornment>
								)}
							</>
						),
					}}
				/>
			</Box>
		</Box>
	);
}
