import {
	JobSummary,
	JobTaskWithJobData,
	JobWatchListData,
	JobWatchListItem,
	User,
	UserMarketingTasks,
} from '../core';

import { ApiClientInterface } from './client';

export default class EmployeeApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.get = this.get.bind(this);
		this.unique = this.unique.bind(this);
		this.add = this.add.bind(this);
		this.update = this.update.bind(this);
		this.resetPassword = this.resetPassword.bind(this);

		this.query = this.query.bind(this);
		this.queryJobs = this.queryJobs.bind(this);
		this.queryJobTasks = this.queryJobTasks.bind(this);
		this.queryMarketingTasks = this.queryMarketingTasks.bind(this);

		this.queryJobWatchList = this.queryJobWatchList.bind(this);
		this.addJobWatchList = this.addJobWatchList.bind(this);
		this.removeJobWatchList = this.removeJobWatchList.bind(this);
	}

	public async get(employeeNumber: string): Promise<User> {
		const response = await this.client.get<User>(
			`/employee/${employeeNumber}`
		);
		return response;
	}

	public async unique(employeeNumber: string): Promise<boolean> {
		const response = await this.client.get<boolean>(
			`/employee/${employeeNumber}/unique`
		);
		return response;
	}

	public async add(user: Partial<User>): Promise<User> {
		const response = await this.client.post<Partial<User>, User>(
			`/employee`,
			user
		);
		return response;
	}

	public async update(user: Partial<User>): Promise<User> {
		const response = await this.client.put<Partial<User>, User>(
			`/employee/${user.EmployeeNumber}`,
			user
		);
		return response;
	}

	public async resetPassword(
		employeeNumber: string,
		password: string
	): Promise<User> {
		const response = await this.client.put<{ password: string }, User>(
			`/employee/${employeeNumber}/password`,
			{ password }
		);
		return response;
	}

	public async query(): Promise<User[]> {
		const response = await this.client.get<User[]>(`/employee`);
		return response || [];
	}

	public async queryJobs(employeeNumber: string): Promise<JobSummary[]> {
		const response = await this.client.get<JobSummary[]>(
			`/employee/${employeeNumber}/jobs`
		);
		return response || [];
	}

	public async queryJobTasks(
		employeeNumber: string
	): Promise<JobTaskWithJobData[]> {
		const response = await this.client.get<JobTaskWithJobData[]>(
			`/employee/${employeeNumber}/jobtasks`
		);
		return response || [];
	}

	public async queryMarketingTasks(
		employeeNumber: string
	): Promise<UserMarketingTasks[]> {
		const response = await this.client.get<UserMarketingTasks[]>(
			`/employee/${employeeNumber}/marketingtasks`
		);
		return response || [];
	}

	/**
	 * Job Watch List
	 */
	public async queryJobWatchList(employeeNumber: string): Promise<JobWatchListItem[]> {
		const response = await this.client.get<JobWatchListItem[]>(
			`/employee/${employeeNumber}/jobwatchlist`
		);
		return response || [];
	}

	public async addJobWatchList(employeeNumber: string, jobId: number): Promise<JobWatchListData> {
		const response = await this.client.post<JobWatchListData, JobWatchListData>(
			`/employee/${employeeNumber}/jobwatchlist`,
			{ EmployeeNumber: employeeNumber, JobID: jobId }
		);
		return response;
	}

	public async removeJobWatchList(employeeNumber: string, jobId: number): Promise<void> {
		await this.client.del(
			`/employee/${employeeNumber}/jobwatchlist/${jobId}`
		);
	}
}
