import { IconButton, ListItemButton, useTheme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
import { JobSummary } from '../../core';
import {
	billingTheme,
	closedTheme,
	getJobStatus,
	newTheme,
	productionTheme,
	salesTheme,
} from './getJobStatus';
import Icon from '@mdi/react';
import { mdiStar, mdiStarOutline } from '@mdi/js';
import { useJobWatchList } from './useJobWatchList';

type JobListItemProps = {
	job: JobSummary;
	onClick: (job: JobSummary) => void;
};

export const JobListItem = ({ job, onClick }: JobListItemProps) => {
	const theme = useTheme();
	const location = useLocation();
	const status = getJobStatus(job);
	const { isOnWatchList, onWatchListToggle } = useJobWatchList(job.JobID);

	let style: any = {};
	if (status.phase === 'Sales') {
		style = {
			borderLeft: `6px solid ${salesTheme.palette.primary.main}`,
			'&.Mui-selected': {
				backgroundColor: salesTheme.palette.primary.main,
				'&:hover': {
					backgroundColor: salesTheme.palette.primary.main,
				},
			},
		}
	}
	if (status.phase === 'Billing') {
		style = {
			borderLeft: `6px solid ${billingTheme.palette.primary.main}`,
			'&.Mui-selected': {
				backgroundColor: billingTheme.palette.primary.main,
				'&:hover': {
					backgroundColor: billingTheme.palette.primary.main,
				},
			},
		}
	}
	if (status.phase === 'Closed') {
		style = {
			borderLeft: `6px solid ${closedTheme.palette.primary.main}`,
			'&.Mui-selected': {
				backgroundColor: closedTheme.palette.primary.main,
				'&:hover': {
					backgroundColor: closedTheme.palette.primary.main,
				},
			},
		}
	}
	if (status.phase === 'Production') {
		style = {
			borderLeft: `6px solid ${productionTheme.palette.primary.main}`,
			'&.Mui-selected': {
				backgroundColor: productionTheme.palette.primary.main,
				'&:hover': {
					backgroundColor: productionTheme.palette.primary.main,
				},
			},
		}
	}
	if (status.phase === 'New') {
		style = {
			borderLeft: `6px solid ${newTheme.palette.primary.main}`,
			'&.Mui-selected': {
				backgroundColor: newTheme.palette.primary.main,
				'&:hover': {
					backgroundColor: newTheme.palette.primary.main,
				},
			},
		}
	}

	return (
		<ListItem
			sx={{
				margin: theme.spacing(0.25, 0),
				flexShrink: 0,
				'& .MuiListItemText-primary': {
					fontWeight: 'bold',
				},
				...style,
			}}
			secondaryAction={
				<Tooltip title={isOnWatchList ? 'Remove from my Watch List' : 'Add to my Watch List'}>
				<IconButton onClick={onWatchListToggle}>
					<Icon
						path={isOnWatchList ? mdiStar : mdiStarOutline}
						size={1}
						color={isOnWatchList ? theme.palette.warning.main : undefined}
					/>
				</IconButton>
				</Tooltip>
			}
		>
			<ListItemButton
				onClick={() => onClick(job)}
				selected={location.pathname.includes(`${job.JobID}`)}
				sx={{
					margin: theme.spacing(0.25, 0),
					padding: theme.spacing(0.5, 2, 0.5, 1),
				}}
			>
				<Tooltip title={job.DamageDesc} enterDelay={1000} leaveDelay={0}>
					<ListItemText
						sx={{
							position: 'relative',
							display: 'block',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							overflow: 'hidden',
						}}
						primary={`${job.JobNumber}: ${job.JobName}`}
						secondary={<span>{job.DamageDesc}</span>}
					/>
				</Tooltip>
			</ListItemButton>
		</ListItem>
	);
};
