import Icon from '@mdi/react';
import { Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

type JobTitleButtonProps = {
	title: string;
	tooltip: string;
	onClick: () => void;
	count: number;
	iconPath: string;
	color?: string;
};

export const ToolbarButton = (props: JobTitleButtonProps) => {
	const {
		title,
		tooltip,
		onClick,
		count,
		iconPath,
		color = '#fff'
	} = props;

	return (
		<Tooltip title={tooltip}>
			<Button
				size="small"
				sx={{ 
					flexDirection: 'column', 
					alignItems: 'flex-end',
				}}
				onClick={onClick}
			>
				<Badge
					badgeContent={count}
					color="secondary"
					overlap="rectangular"
					sx={{
						top: 6,
						padding: '0 2px',
						alignSelf: 'center'
					}}
				>
					<Icon path={iconPath} size={0.9} style={{ alignSelf: 'center' }} color={color}/>
				</Badge>
				{/* {isWideLayout && ( */}
					<Typography variant="caption" sx={{ color, marginTop: '8px', alignSelf: 'center' }}>
						<strong>{title}</strong>
					</Typography>
				{/* )} */}
			</Button>
		</Tooltip>
	);
};
