import { useCallback } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { useAppDispatch, useAppSelector } from "../../store";
import { addWatchListItem, removeWatchListItem, selectWatchListItems } from "../../store/watchlist";

export const useJobWatchList = (jobId: number) => {
    const dispatch = useAppDispatch();
    const { api, user } = useAppContext();
    const items = useAppSelector(selectWatchListItems);

    const onWatchListToggle = useCallback(async () => {
        const item = items.find(x => x.JobID === jobId);
        try {
            if (item) {
                dispatch(removeWatchListItem({ EmployeeNumber: user.EmployeeNumber, JobID: jobId }));
                await api.employee.removeJobWatchList(user.EmployeeNumber, jobId);
            } else {
                dispatch(addWatchListItem({ EmployeeNumber: user.EmployeeNumber, JobID: jobId }));
                await api.employee.addJobWatchList(user.EmployeeNumber, jobId);
            }
        } catch (error) {
            console.error(error);
        }
    }, [items, jobId, dispatch, user.EmployeeNumber, api.employee])

    return {
        isOnWatchList: items.find(x => x.JobID === jobId) !== undefined,
        onWatchListToggle,
    }
}