import { mdiPlusCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import { CompanyNoteData } from '../../core';
import { Container, Dialog } from '../common';
import { AddNote } from '../notes/AddNoteSimple';
import { NoteListItem } from '../notes/NoteListItemSimple';
import { Box } from '@mui/material';

type CompanyNoteListProps = {
	companyId: number;
	onCountChanged: (count: number) => void;
	show: boolean;
};

export const CompanyNoteList = (props: CompanyNoteListProps) => {
	const { companyId, onCountChanged, show } = props;
	const { api, user } = useAppContext();
	const theme = useTheme();
	const [notes, setNotes] = useState<CompanyNoteData[]>([]);
	const [editNote, setEditNote] = useState<CompanyNoteData>();
	const [showAddNote, setShowAddNote] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (companyId) {
			api.company
				.queryNotes(companyId)
				.then(setNotes)
				.catch(console.error);
		}
	}, [api.company, companyId]);

	useEffect(() => {
		onCountChanged(notes.length);
	}, [notes.length, onCountChanged]);

	if (!show) return null;

	const handleAddNewNote = (text: string) => {
		const note: CompanyNoteData = {
			CompanyNoteID: 0,
			CompanyID: companyId,
			NoteDate: new Date(),
			NoteText: text,
			EmployeeNumber: user.EmployeeNumber,
		};
		api.company
			.addNote(companyId, note)
			.then((inserted) => {
				setNotes((s) => {
					const ns = [...s];
					ns.push(inserted);
					return orderBy(ns, ['NoteDate'], ['desc']);
				});
				setShowAddNote(false);
				enqueueSnackbar('Note Added', { variant: 'success' });
			})
			.catch((error) => {
				enqueueSnackbar(error.message, { variant: 'error' });
			});
	};

	return (
		<Container component="CompanyNoteList">
			{!showAddNote && (
				<Toolbar disableGutters sx={theme => ({
					padding: theme.spacing(0.5),
					borderBottom: `1px solid ${theme.palette.divider}`,
				})}>
					<span style={{ flexGrow: 1 }} />
					<Tooltip
						title="Add Note"
						style={{ margin: theme.spacing(0, 1) }}
					>
						<IconButton
							size="small"
							onClick={() => setShowAddNote(true)}
						>
							<Icon path={mdiPlusCircleOutline} size={1} />
						</IconButton>
					</Tooltip>
				</Toolbar>
			)}
			{showAddNote && (
				<Box sx={{ padding: theme.spacing(0, 2, 0, 0) }}>
					<AddNote
						onAddNote={handleAddNewNote}
						onClose={() => setShowAddNote(false)}
					/>
				</Box>
			)}
			{!showAddNote && (
				<Container component="CompanyNoteListItems" scrollable>
					{notes.map((note) => (
						<NoteListItem
							key={note.CompanyNoteID}
							content={note.NoteText}
							date={note.NoteDate}
							onEditNote={() => setEditNote(note)}
							employeeNumber={note.EmployeeNumber}
						/>
					))}
				</Container>
			)}
			{!!editNote && (
				<Dialog
					isOpen={true}
					title="Edit Note"
					cancelAction={{
						text: 'Cancel',
						onClick: () => setEditNote(undefined),
					}}
					positiveAction={{
						text: 'Save Changes',
						onClick: () => {
							api.company
								.updateNote(editNote)
								.then(() => {
									setNotes((s) => {
										const ns = [...s];
										const index = ns.findIndex(
											(x) =>
												x.CompanyNoteID ===
												editNote.CompanyNoteID
										);
										if (index !== -1) {
											ns[index] = editNote;
										}
										setTimeout(
											() => setEditNote(undefined),
											500
										);
										return ns;
									});
								})
								.catch(console.error);
						},
					}}
					onClose={() => setEditNote(undefined)}
				>
					<Box sx={theme => ({ padding: theme.spacing(2) })}>
						<TextField
							aria-label="Note"
							title="Note"
							size="small"
							value={editNote.NoteText}
							onChange={(e) =>
								setEditNote({
									...editNote,
									NoteText: e.target.value,
								})
							}
							variant="outlined"
							multiline
							margin="dense"
							minRows={5}
							fullWidth
							InputLabelProps={{ shrink: true }}
						/>
					</Box>
				</Dialog>
			)}
		</Container>
	);
};
