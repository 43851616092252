import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import { ContactData, LookupTypes } from '../../core';
import { AutoCompleteLookup, BottomSpace, EmployeeDropDown } from '../common';

const fields = [
	{ key: 'SalesRepEmp1', label: 'RC / Estimator' },
	{ key: 'SalesRepEmp2', label: 'Marketer' },
	{ key: 'SalesRepEmp3', label: 'Sales Rep 3' },
	{ key: 'SalesRepEmp4', label: 'Sales Rep 4' },
	{ key: 'SalesRepEmp5', label: 'Sales Rep 5' },
];

type ContactSalesRepsProps = {
	contact: ContactData | null | undefined;
	onChange: (data: Partial<ContactData>) => void;
	canEdit?: boolean;
};

export const ContactSalesReps = ({
	contact,
	onChange,
	canEdit,
}: ContactSalesRepsProps) => {
	if (!contact) return null;

	return (
		<Box sx={theme => ({
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			padding: theme.spacing(1),
			height: '100%',
			overflowX: 'hidden',
			overflowY: 'auto',
			marginTop: theme.spacing(1),
		})}>
			{fields.map((item) => (
				<Box key={item.key} sx={theme => ({ mb: theme.spacing(2)})}>
				<EmployeeDropDown
					key={item.key}
					label={item.label}
					placeholder={item.label}
					emptyOption={{ value: '', label: 'None' }}
					value={(contact as any)[item.key] || null}
					disabled={!canEdit}
					onChange={(value) =>
						onChange({
							...contact,
							[item.key]: value || null,
						})
					}
					filter={(employees) =>
						employees.filter((x) => x.isActive && x.usr9)
					}
				/>
				</Box>
			))}
			<Divider sx={theme => ({ mb: theme.spacing(2)})} />
			<AutoCompleteLookup
				label="Grade"
				placeholder="Grade"
				lookupType={LookupTypes.Grade}
				value={contact.GradeID || null}
				disabled={!canEdit}
				onChange={(value) => {
					onChange({
						...contact,
						GradeID: value || undefined,
					});
				}}
			/>
			<BottomSpace />
		</Box>
	);
};
