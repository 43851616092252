import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import {
	ContactViewData,
	JobSiteContactData,
	LookupTypes,
	validatePhoneNumber,
} from '../../../core';
import {
	AutoCompleteLookup,
	Container,
	Dialog,
	ErrorMessage,
	Loading,
} from '../../common';
import { useLookupTypes } from '../../lookups';
import ContactSelection from "../../selection/ContactSelection";

type State = {
	working: boolean;
	error?: any;
	data: Partial<JobSiteContactData>;
};

export type Props = {
	jobId: number;
	contact?: ContactViewData;
	onJobsiteContactAdded: (jobSiteContact: JobSiteContactData) => void;
	onClose: () => void;
};

export const AddJobsiteContactDialog = ({
	jobId,
	onClose,
	onJobsiteContactAdded,
}: Props) => {
	const { api } = useAppContext();
	const [contactTypes] = useLookupTypes([LookupTypes.JobsiteContactType]);
	const defaultJobSiteContactId =
		contactTypes?.find((x: any) => x.Name === 'Maintenance')?.ID ||
		undefined;

	const [state, setState] = React.useState<State>({
		working: false,
		data: {
			JobID: jobId,
			JobsiteContactTypeID: defaultJobSiteContactId,
		},
	});

	const handleChange = (property: string, value: any) => {
		setState((s) => ({ ...s, data: { ...s.data, [property]: value } }));
	};

	const handleContactChange = (contact: ContactViewData | null) => {
		if (contact) {
			setState((s) => ({
				...s,
				data: {
					JobID: jobId,
					ContactID: contact.ContactID,
					JobsiteContactName: contact.fullname,
					JobsiteContactTypeID:
						contact.ContactTypeID || defaultJobSiteContactId,
					PhoneNumber: contact.phonenumber || undefined,
					Description: contact.shortnote || '',
				},
			}));
		} else {
			setState((s) => ({
				...s,
				data: {
					JobID: jobId,
					JobsiteContactTypeID: defaultJobSiteContactId,
				},
			}));
		}
	};

	const createJobsiteContact = async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			const added = await api.job.addJobsiteContact(state.data);
			onJobsiteContactAdded(added);
			// setState((s) => ({ ...s, working: false }));
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	};

	const isContactValid = !!state.data.JobsiteContactTypeID;

	return (
		<Dialog
			isOpen={true}
			title={`Add Jobsite Contact`}
			cancelAction={{
				text: 'Cancel',
				onClick: onClose,
				isDisabled: () => state.working,
			}}
			positiveAction={{
				text: 'Add Contact',
				isDisabled: () =>
					state.working || !!state.error || !isContactValid,
				onClick: createJobsiteContact,
			}}
			onClose={onClose}
		>
			<Container
				component="AddJobContact"
				scrollable
				sx={theme => ({ padding: theme.spacing(0, 2, 2, 2) })}
			>
				<ErrorMessage
					error={state.error}
					onRetry={() =>
						setState((s) => ({ ...s, error: undefined }))
					}
				/>
				{state.working && <Loading />}
				<Grid container direction="column" spacing={0}>
					<Grid item sx={theme => ({ px: theme.spacing(2), mb: theme.spacing(2) })}>
						<ContactSelection
							title="Add Contact"
							onSelected={(contact) => {
								handleContactChange(contact || null)
							}}
						/>
					</Grid>
					<Grid item sx={theme => ({ px: theme.spacing(2), mb: theme.spacing(2) })}>
						<Grid item style={{ flexGrow: 1 }}>
							<TextField
								label="Name"
								aria-label="Name"
								title="Name"
								value={state.data.JobsiteContactName || ''}
								onChange={(e) =>
									handleChange(
										'JobsiteContactName',
										e.target.value
									)
								}
								onFocus={(e) => e.target.select()}
								variant="outlined"
								margin="dense"
								fullWidth
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
					</Grid>
					<Grid item sx={theme => ({ px: theme.spacing(2), mb: theme.spacing(2) })}>
						<AutoCompleteLookup
							lookupType={LookupTypes.JobsiteContactType}
							label="Type"
							placeholder="Type"
							required
							value={state.data.JobsiteContactTypeID || null}
							onChange={(value) =>
								handleChange('JobsiteContactTypeID', value)
							}
							error={!state.data.JobsiteContactTypeID}
						/>
					</Grid>
					<Grid item sx={theme => ({ px: theme.spacing(2), mb: theme.spacing(2) })}>
						<TextField
							aria-label="Phone Number"
							title="Phone Number"
							label="Phone Number"
							value={state.data.PhoneNumber || ''}
							onChange={(e) =>
								handleChange('PhoneNumber', e.target.value)
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							required
							fullWidth
							InputLabelProps={{ shrink: true }}
							error={!validatePhoneNumber(state.data.PhoneNumber)}
						/>
					</Grid>
					<Grid item sx={theme => ({ px: theme.spacing(2), mb: theme.spacing(2) })}>
						<TextField
							aria-label="Description"
							title="Description"
							label="Description"
							value={state.data.Description || ''}
							onChange={(e) =>
								handleChange('Description', e.target.value)
							}
							onFocus={(e) => e.target.select()}
							variant="outlined"
							margin="dense"
							multiline
							fullWidth
							InputLabelProps={{ shrink: true }}
							minRows={4}
						/>
					</Grid>
				</Grid>
			</Container>
		</Dialog>
	);
};
