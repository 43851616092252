/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./types";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getTriggers = /* GraphQL */ `query GetTriggers($id: ID!) {
  getTriggers(id: $id) {
    id
    type
    status
    progress
    createdAt
    updatedAt
    deleteAfter
    payload
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTriggersQueryVariables,
  APITypes.GetTriggersQuery
>;
export const listTriggers = /* GraphQL */ `query ListTriggers(
  $filter: ModelTriggersFilterInput
  $limit: Int
  $nextToken: String
) {
  listTriggers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      status
      progress
      createdAt
      updatedAt
      deleteAfter
      payload
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTriggersQueryVariables,
  APITypes.ListTriggersQuery
>;
