import {
	mdiClose,
	mdiDownloadCircleOutline,
	mdiTextBoxPlusOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { LinearProgress, ListItemButton } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React from 'react';
// import awsExports from '../../../aws-exports';
import { useAppContext } from '../../../contexts/AppContext';
import { GenerateS3FormRequest, JobData, JobDetail } from '../../../core';
import { useS3FileBrowserContext } from '../../browser/S3FileBrowserContext';
import { storage } from '../../browser/StorageProvider';
import { Card, Container } from '../../common';
import { useWindowOpen } from '../../useWindowOpen';

type Props = {
	canEditJob?: boolean;
	job?: JobDetail;
	onChange: (data: Partial<JobData>) => void;
};

export const JobOtherFields = ({ job, onChange, canEditJob }: Props) => {
	const theme = useTheme();
	const { api, bucket } = useAppContext();
	const { reload } = useS3FileBrowserContext();
	const [errorMessage, setErrorMessage] = React.useState('');
	const [busy, setBusy] = React.useState(false);
	const snackbar = useSnackbar();

	const open = useWindowOpen();

	const handleOpenFile = async (key: string) => {
		const url = await storage.getDownloadUrl(key);
		if (url) {
			open(url);
		}
	};

	if (!job) return null;

	const forms = [
		{
			key: '1',
			label: 'Estimate Letter - Preliminary',
			existing: 'public/templates/Estimate Letter - Preliminary.docx',
		},
		{
			key: '2',
			label: 'Estimate Letter - Change Order',
			existing: 'public/templates/Estimate Letter - Change Order.docx',
		},
		{
			key: '3',
			label: 'Estimate Letter - Start of Job',
			existing: 'public/templates/Estimate Letter - Start of Job.docx',
		},
		{
			key: '4',
			label: 'TBA 1 Year Limited Warranty',
			existing: 'public/templates/TBA 1 Year Limited Warranty.docx',
		},
		{
			key: '5',
			label: 'TBA 2 Year Limited Warranty',
			existing: 'public/templates/TBA 2 Year Limited Warranty.docx',
		},
		{
			key: '6',
			label: 'TBA Certificate of Completion',
			existing: 'public/templates/TBA Certificate of Completion.docx',
		},
		{
			key: '7',
			label: 'TBA Work Authorization Agreement',
			existing: 'public/templates/TBA Work Authorization Agreement.docx',
		},
		{
			key: '8',
			label: 'TBA Thank You Letter',
			existing: 'public/templates/TBA Thank You letter 5-08.docx',
		},
		{
			key: '9',
			label: 'TBA SURVEY',
			existing: 'public/templates/TBA SURVEY.docx',
		},
		{
			key: '11',
			label: 'TBA Work Order',
			existing: 'public/templates/TBA Work Order.docx',
		},
	];

	const fields = [
		{
			key: 'UserDefined1',
			label: 'User Defined 1',
			value: job.UserDefined1,
		},
		{
			key: 'UserDefined2',
			label: 'User Defined 2',
			value: job.UserDefined2,
		},
		{
			key: 'UserDefined3',
			label: 'User Defined 3',
			value: job.UserDefined3,
		},
		{
			key: 'UserDefined4',
			label: 'User Defined 4',
			value: job.UserDefined4,
		},
		{
			key: 'UserDefined5',
			label: 'User Defined 5',
			value: job.UserDefined5,
		},
		{
			key: 'UserDefined6',
			label: 'User Defined 6',
			value: job.UserDefined6,
		},
		{
			key: 'UserDefined7',
			label: 'User Defined 7',
			value: job.UserDefined7,
		},
		{
			key: 'UserDefined8',
			label: 'User Defined 8',
			value: job.UserDefined8,
		},
		{
			key: 'UserDefined9',
			label: 'User Defined 9',
			value: job.UserDefined9,
		},
		{
			key: 'UserDefined10',
			label: 'User Defined 10',
			value: job.UserDefined10,
		},
	];

	const generateForm = async (name: string, key: string) => {
		const request: GenerateS3FormRequest = {
			bucket,
			key, //`public/templates/${name}.docx`,
			targetKey: `public/${job.JobID}/Forms/${name}.docx`,
			data: null,
		};
		try {
			setBusy(true);
			await api.templates.generateForm(job.JobID, request);
			snackbar.enqueueSnackbar(`${name}.docx Generated`, {
				variant: 'success',
			});
			reload();
		} catch (error) {
			setErrorMessage(`${error}`);
			snackbar.enqueueSnackbar(`An error occurred`, { variant: 'error' });
		} finally {
			setBusy(false);
		}
	};

	return (
		<Container component="JobFormsFields" scrollable>
			<Card light label="Generate Forms" sx={{
				margin: 0,
				padding: 0,
				flexGrow: 0,
			}}>
				{busy && <LinearProgress color="secondary" />}
				{!busy && <div style={{ height: '4px' }} />}
				{!!errorMessage && (
					<div
						style={{
							padding: theme.spacing(1),
							backgroundColor: theme.palette.error.light,
							color: theme.palette.error.contrastText,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}
					>
						{errorMessage}
						<IconButton
							size="small"
							onClick={() => setErrorMessage('')}
						>
							<Icon
								path={mdiClose}
								size={1}
								color={theme.palette.error.contrastText}
							/>
						</IconButton>
					</div>
				)}
				<List>
					{forms.map((item) => (
						<ListItemButton
							key={item.key}
							onClick={() => generateForm(item.label, item.existing)}
							disabled={busy}
						>
							<ListItem
								secondaryAction={(
									<Tooltip
										title={
											item.existing
												? 'Download form'
												: 'Generate new form'
										}
									>
										<IconButton
											onClick={() =>
												item.existing
													? handleOpenFile(item.existing)
													: generateForm(item.label, item.existing)
											}
											disabled={busy}
										>
											<Icon
												path={
													!!item.existing
														? mdiDownloadCircleOutline
														: mdiTextBoxPlusOutline
												}
												size={1}
											/>
										</IconButton>
									</Tooltip>
								)}
							>
								<Tooltip title="Click to generate a new form">
									<ListItemText secondary={item.label} />
								</Tooltip>
							</ListItem>
						</ListItemButton>
					))}
				</List>
			</Card>

			<Card light label="Custom Fields" sx={{
				margin: 0,
				padding: 0,
				flexGrow: 0,
			}} contentStyle={{ padding: theme.spacing(2, 1, 2, 0.5)}}>
				{fields.map((item) => (
					<TextField
						key={item.key}
						label={item.label}
						aria-label={item.label}
						fullWidth
						title={item.label}
						value={item.value || ''}
						onChange={(e) =>
							onChange({ ...job, [item.key]: e.target.value })
						}
						disabled={!canEditJob}
						sx={{ mb: theme.spacing(2) }}
						InputLabelProps={{ shrink: true }}
					/>
				))}
			</Card>
		</Container>
	);
};
