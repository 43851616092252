import { useAppContext } from '../../contexts/AppContext';
import { JobSummary } from '../common/JobSummary';

export type Props = {
	contactId: number;
};

export const ContactJobSummary = ({ contactId }: Props) => {
	const { api } = useAppContext();

	return (
		<>
			<JobSummary id={contactId} title="Referral Data" execute={api.contact.queryReferralSummary} />
			<JobSummary id={contactId} title="Summary Data" execute={api.contact.queryJobSummary} />
		</>
	);
};
