import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useEffect, useState } from 'react';
import { TriggerStatus, TriggerStatusTypes, TriggerTypes } from '../../core';
import { triggersProvider } from '../graphql/GraphQLTriggersProvider';
import { StorageProvider } from './StorageProvider';
import { downloadBlob, getFileNameFromKey } from './utils';
import { Alert, Box } from '@mui/material';

interface State {
	status: TriggerStatus | undefined;
	progress?: string | null;
	error?: any;
}

const initialState: State = {
	status: undefined,
	progress: '',
}

type Props = {
	folder: string;
	filename: string;
	onClose: () => void;
};

const storage = new StorageProvider();

const getFolderDisplay = (folder: string): string => {
	const s = folder.split('/');
	s.shift(); // public
	s.shift(); // jobid
	return s.join('/');
}

export const ArchiveDialog = ({ folder, filename, onClose }: Props) => {
	const [state, setState] = useState<State>(initialState);

	useEffect(() => {
		const unsubscribe = triggersProvider.subscribe(
			{ id: { eq: filename } },
			{
				onCreate: (data) => setState(s => ({ ...s, status: data.status as TriggerStatus, progress: data.progress })),
				onUpdate: (data) => setState(s => ({ ...s, status: data.status as TriggerStatus, progress: data.progress })),
				onDelete: () => setState(({ ...initialState, error: new Error('The download has been deleted. Please try again') })),
			}
		)
		return () => unsubscribe();
	}, [filename, onClose]);

	const downloadArchive = async () => {
		const targetFile = getFileNameFromKey(filename);
		if (targetFile) {
			const url = await storage.getDownloadUrl(filename);
			downloadBlob(url, targetFile);
			setTimeout(onClose, 700);
		}
	}

	const createArchive = async () => {
		try {
			const dt = new Date().toISOString();
			await triggersProvider.createTrigger({
				id: filename,
				type: TriggerTypes.ArchiveFolder,
				status: TriggerStatusTypes.Pending,
				progress: '...',
				createdAt: dt,
				updatedAt: dt,
				payload: JSON.stringify({
					folder,
					filename,
				}),
			})
		} catch (error) {
			setState(s => ({ ...initialState, error }));
		}
	}

	return (
		<Dialog 
			title="Download" 
			open={true} 
			onClose={onClose}
			PaperProps={{
				sx: {
					minWidth: '700px',
					minHeight: '300px'
				}
			}}
		>
			<AppBar position="relative">
				<Toolbar variant="dense">
					<span style={{ flexGrow: 1, fontWeight: 500 }}>Download {state.status}</span>
					<IconButton
						size="small"
						edge="end"
						aria-label="Close"
						onClick={onClose}
					>
						<Icon path={mdiClose} size={1} color="#fff" />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent sx={theme => ({
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				width: '100%',
				padding: theme.spacing(4),
				margin: 0,
			})}>
				<Box sx={theme => ({ mb: theme.spacing(1) })}>
					<strong>Folder: </strong> {getFolderDisplay(folder) || 'All Job Files'}
				</Box>
				{!!state.status && (
					<Box sx={theme => ({ mb: theme.spacing(1) })}>
						<strong>Status: </strong> {state.status || ''}
					</Box>
				)}
				{!state.status && (
					<Box sx={theme => ({ mb: theme.spacing(1) })}>
						Click "Queue Download" to begin. Processing will take several minutes depending on how many files are included in the archive.  
						You can leave this dialog open and watch the status or come back to the files tab under the job to access the download later.
						<br/><br/>
						Archives will only be available for download for 3 days.
					</Box>
				)}
				{(state.status === 'Processing' || state.status === 'Queued') && (
					<Box sx={theme => ({ mb: theme.spacing(1) })}>
						<strong>Progress: </strong> {state.progress || ''}
					</Box>
				)}
				{state.status === 'Error' && (
					<Box sx={theme => ({ mb: theme.spacing(1) })}>
						<Alert variant="filled" color="error">
							{`${state.error}`}
						</Alert>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="secondary" onClick={onClose}>
					Close
				</Button>
				<span style={{ flexGrow: 1 }} />
				{state.status === undefined && (
					<Button variant="contained" color="primary" onClick={createArchive}>
						Queue Download
					</Button>
				)}
				{state.status === 'Error' && (
					<Button variant="contained" color="primary" onClick={createArchive}>
						Try Again
					</Button>
				)}
				{state.status !== undefined && state.status !== 'Error' && (
					<Button variant="contained" color="primary" onClick={downloadArchive} disabled={state.status !== 'Complete'}>
						Download
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
