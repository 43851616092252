
export type TriggerType = 'Archive Folder' | 'Move Files';

export const TriggerTypes = {
    ArchiveFolder: 'Archive Folder',
    MoveFiles: 'Move Files',
}

export type TriggerStatus = 'Pending' | 'Queued' | 'Processing' | 'Error' | 'Complete';

export const TriggerStatusTypes = {
    Pending: 'Pending',
    Queued: 'Queued',
    Processing: 'Processing',
    Error: 'Error',
    Complete: 'Complete',
}