import {
	CompanySummary,
	ContactAddressViewData,
	ContactData,
	ContactEmailViewData,
	ContactNoteData,
	ContactPhoneData,
	ContactPhoneNumberViewData,
	ContactSearchOptions,
	ContactViewData,
	CreateContactRequest,
	JobSummary,
	JobTotals,
	MarketingTaskData,
	PrimaryEmailAddressData,
	PrimaryPhoneNumberData,
	PrimaryStreetAddressData,
	UpdateRequest,
	stringifySearchOptions
} from '../core';


import { ApiClientInterface } from './client';

export default class ContactApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
		this.query = this.query.bind(this);
		this.count = this.count.bind(this);
		this.add = this.add.bind(this);
		this.get = this.get.bind(this);
		this.update = this.update.bind(this);

		this.getView = this.getView.bind(this);

		this.queryAddresses = this.queryAddresses.bind(this);
		this.addAddress = this.addAddress.bind(this);
		this.updateAddress = this.updateAddress.bind(this);
		this.setPrimaryAddress = this.setPrimaryAddress.bind(this);
		this.removeAddress = this.removeAddress.bind(this);

		this.queryEmailAddresses = this.queryEmailAddresses.bind(this);
		this.addEmailAddress = this.addEmailAddress.bind(this);
		this.updateEmailAddress = this.updateEmailAddress.bind(this);
		this.setPrimaryEmailAddress = this.setPrimaryEmailAddress.bind(this);
		this.removeEmailAddresses = this.removeEmailAddresses.bind(this);

		this.queryPhoneNumbers = this.queryPhoneNumbers.bind(this);
		this.addPhoneNumber = this.addPhoneNumber.bind(this);
		this.updatePhoneNumber = this.updatePhoneNumber.bind(this);
		this.setPrimaryPhoneNumber = this.setPrimaryPhoneNumber.bind(this);
		this.removePhoneNumber = this.removePhoneNumber.bind(this);

		this.queryNotes = this.queryNotes.bind(this);
		this.addNote = this.addNote.bind(this);
		this.updateNote = this.updateNote.bind(this);

		this.queryCompanies = this.queryCompanies.bind(this);
		this.queryJobs = this.queryJobs.bind(this);
		this.queryMarketingTasks = this.queryMarketingTasks.bind(this);
		this.completeTask = this.completeTask.bind(this);

		this.queryJobSummary = this.queryJobSummary.bind(this);
		this.queryReferralSummary = this.queryReferralSummary.bind(this);

		this.addMarketingTask = this.addMarketingTask.bind(this);
	}

	public async query(
		options: ContactSearchOptions
	): Promise<ContactViewData[]> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<ContactViewData[]>(
			`/contact?${search}`
		);
		return response;
	}

	public async count(options: ContactSearchOptions): Promise<number> {
		const search = stringifySearchOptions(options);
		const response = await this.client.get<number>(
			`/contact?${search}&count=true`
		);
		return response;
	}

	public async add(request: CreateContactRequest): Promise<ContactData> {
		const response = await this.client.post<
			CreateContactRequest,
			ContactData
		>(`/contact`, request);
		return response;
	}

	public async get(contactId: number): Promise<ContactData> {
		const response = await this.client.get<ContactData>(
			`/contact/${contactId}`
		);
		return response;
	}

	public async update(request: UpdateRequest): Promise<void> {
		await this.client.patch<UpdateRequest, void>(
			`/contact/${request.id}`,
			request
		);
	}

	// Contact View
	public async getView(contactId: number): Promise<ContactViewData> {
		const response = await this.client.get<ContactViewData>(
			`/contact/${contactId}/view`
		);
		return response;
	}

	// Contact Address
	public async queryAddresses(
		contactId: number
	): Promise<ContactAddressViewData[]> {
		const response = await this.client.get<ContactAddressViewData[]>(
			`/contact/${contactId}/address`
		);
		return response;
	}

	public async addAddress(
		contactId: number,
		address: Partial<PrimaryStreetAddressData>
	): Promise<ContactAddressViewData> {
		const response = await this.client.post<
			Partial<PrimaryStreetAddressData>,
			ContactAddressViewData
		>(`/contact/${contactId}/address`, address);
		return response;
	}

	public async updateAddress(
		contactId: number,
		address: PrimaryStreetAddressData
	): Promise<ContactAddressViewData> {
		const response = await this.client.put<
			PrimaryStreetAddressData,
			ContactAddressViewData
		>(`/contact/${contactId}/address/${address.AddressID}`, address);
		return response;
	}

	public async removeAddress(
		contactId: number,
		addressId: number
	): Promise<void> {
		await this.client.del(
			`/contact/${contactId}/address/${addressId}`
		);
	}

	public async setPrimaryAddress(
		contactId: number,
		addressId: number
	): Promise<void> {
		await this.client.patch<undefined, undefined>(
			`/contact/${contactId}/address/${addressId}`
		);
	}

	// Contact Phone
	public async queryPhoneNumbers(
		contactId: number
	): Promise<ContactPhoneNumberViewData[]> {
		const response = await this.client.get<ContactPhoneNumberViewData[]>(
			`/contact/${contactId}/phone`
		);
		return response;
	}

	public async addPhoneNumber(
		contactId: number,
		phone: Partial<ContactPhoneData>
	): Promise<ContactPhoneNumberViewData> {
		const response = await this.client.post<
			Partial<ContactPhoneData>,
			ContactPhoneNumberViewData
		>(`/contact/${contactId}/phone`, phone);
		return response;
	}

	public async updatePhoneNumber(
		contactId: number,
		phone: PrimaryPhoneNumberData
	): Promise<ContactPhoneNumberViewData> {
		const response = await this.client.put<
			PrimaryPhoneNumberData,
			ContactPhoneNumberViewData
		>(`/contact/${contactId}/phone/${phone.PhoneID}`, phone);
		return response;
	}

	public async removePhoneNumber(
		contactId: number,
		phoneId: number
	): Promise<void> {
		await this.client.del(
			`/contact/${contactId}/phone/${phoneId}`
		);
	}

	public async setPrimaryPhoneNumber(
		contactId: number,
		phoneId: number
	): Promise<void> {
		await this.client.patch<undefined, undefined>(
			`/contact/${contactId}/phone/${phoneId}`
		);
	}

	// Contact Email
	public async queryEmailAddresses(
		contactId: number
	): Promise<ContactEmailViewData[]> {
		const response = await this.client.get<ContactEmailViewData[]>(
			`/contact/${contactId}/email`
		);
		return response;
	}

	public async addEmailAddress(
		contactId: number,
		email: Partial<PrimaryEmailAddressData>
	): Promise<PrimaryEmailAddressData> {
		const response = await this.client.post<
			Partial<PrimaryEmailAddressData>,
			PrimaryEmailAddressData
		>(`/contact/${contactId}/email`, email);
		return response;
	}

	public async updateEmailAddress(
		contactId: number,
		email: PrimaryEmailAddressData
	): Promise<ContactEmailViewData> {
		const response = await this.client.put<
			PrimaryEmailAddressData,
			ContactEmailViewData
		>(`/contact/${contactId}/email/${email.EmailID}`, email);
		return response;
	}

	public async removeEmailAddresses(
		contactId: number,
		emailId: number
	): Promise<void> {
		await this.client.del(
			`/contact/${contactId}/email/${emailId}`
		);
	}

	public async setPrimaryEmailAddress(
		contactId: number,
		emailId: number
	): Promise<void> {
		await this.client.patch<undefined, undefined>(
			`/contact/${contactId}/email/${emailId}`
		);
	}

	// Contact Note
	public async queryNotes(contactId: number): Promise<ContactNoteData[]> {
		const response = await this.client.get<ContactNoteData[]>(
			`/contact/${contactId}/note`
		);
		return response;
	}

	public async addNote(
		contactId: number,
		note: Partial<ContactNoteData>
	): Promise<ContactNoteData> {
		const response = await this.client.post<
			Partial<ContactNoteData>,
			ContactNoteData
		>(`/contact/${contactId}/note`, note);
		return response;
	}

	public async updateNote(
		contactId: number,
		note: Partial<ContactNoteData>
	): Promise<ContactNoteData> {
		const response = await this.client.put<
			Partial<ContactNoteData>,
			ContactNoteData
		>(`/contact/${contactId}/note/${note.ContactNoteID}`, note);
		return response;
	}

	// Contact Jobs
	public async queryJobs(contactId: number): Promise<JobSummary[]> {
		const response = await this.client.get<JobSummary[]>(
			`/contact/${contactId}/job`
		);
		return response;
	}

	// Contact Companies
	public async queryCompanies(contactId: number): Promise<CompanySummary[]> {
		const response = await this.client.get<CompanySummary[]>(
			`/contact/${contactId}/company`
		);
		return response;
	}

	// Contact Tasks
	public async queryMarketingTasks(
		contactId: number
	): Promise<MarketingTaskData[]> {
		const response = await this.client.get<MarketingTaskData[]>(
			`/contact/${contactId}/task`
		);
		return response;
	}

	public async addMarketingTask(
		contactId: number,
		task: Partial<MarketingTaskData>
	): Promise<MarketingTaskData> {
		const response = await this.client.post<
			Partial<MarketingTaskData>,
			MarketingTaskData
		>(`/contact/${contactId}/marketingtask`, task);
		return response;
	}

	public async completeTask(
		contactId: number,
		taskId: number
	): Promise<void> {
		await this.client.put<any, void>(
			`/contact/${contactId}/task/${taskId}/complete`,
			{}
		);
	}

	// Job Summary Data
	public async queryJobSummary(contactId: number): Promise<JobTotals[]> {
		const response = await this.client.get<JobTotals[]>(
			`/contact/${contactId}/jobsummary`
		);
		return response;
	}
	
	// Job Referral Data
	public async queryReferralSummary(contactId: number): Promise<JobTotals[]> {
		const response = await this.client.get<JobTotals[]>(
			`/contact/${contactId}/referralsummary`
		);
		return response;
	}


}
