import { Box, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { Dialog, ErrorMessage, Loading } from '../common';
import { MoveFilesTriggerPayload, ThumbnailToken, TriggerStatus, TriggerStatusTypes, TriggerTypes } from '../../core';
import { triggersProvider } from '../graphql/GraphQLTriggersProvider';


type ActionType = 'file' | 'folder';

export interface RenameDialogAction {
	type: ActionType;
	sourceKey: string;
}

type Props = {
	sourceKey: string;
	type: ActionType;
	onClose: () => void;
};

type State = {
	prefix: string;
	name: string;
	ext?: string;
	employeeNumber?: string;
	timestamp?: string;
	target: string;
	busy: boolean;
	error?: any;
};

interface ProcessState {
	status: TriggerStatus | undefined;
	progress?: string | null;
	error?: any;
}

const initialProcessState: ProcessState = {
	status: undefined,
	progress: '',
}

// const str = "BARN99_2024-09-16T17-48-34_SomeArbitratryValue";
const parseFileName = (str: string) => {
	const match = str.match(/^([a-zA-Z]{4}\d{2})_([0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}-[0-9]{2}-[0-9]{2})_(.*)$/);

	if (match) {
		// match[0] is the entire matched string
		// match[1] is the 4 letters + 2 digits
		// match[2] is the date and time
		// match[3] is the remaining string
		const [filename, ext] = match[3].split('.');

		return {
			employeeNumber: match[1],
			timestamp: match[2],
			filename: filename.replace(ThumbnailToken, ''),
			ext,
		}

	} else {
		console.log("No match found");
	}

}

type ParseResult = { 
	prefix: string; 
	name: string; 
	ext?: string;
	employeeNumber?: string;
	timestamp?: string;
};

const parseSourceKey = (type: ActionType, sourceKey: string): ParseResult => {
	const key = type === 'folder' && sourceKey.endsWith('/')
		? sourceKey.replace(/\/$/, '').replace(ThumbnailToken, '')
		: sourceKey;

	const segments = key.split('/');
	const last = segments.pop();

	const result: ParseResult = {
		prefix: segments.join('/'),
		name: '',
		ext: '',
	}

	if (type === 'file') {
		if (!!last) {
			const fileData = parseFileName(last);
			if (fileData) {
				result.employeeNumber = fileData.employeeNumber;
				result.timestamp = fileData.timestamp;
				result.name = fileData.filename;
				result.ext = fileData.ext;
			} else {
				const [name, ext] = last.split('.');
				result.name = name;
				result.ext = ext;
			}
		}
	} else if (type === 'folder') {
		if (!!last) {
			result.name = last;
		}
	}

	return result;
}

const getTarget = (type: ActionType, state: State): string => {
	if (type === 'folder') {
		return `${state.prefix}/${state.name}/`
	}

	const filename: string[] = [];
	if (state.employeeNumber) {
		filename.push(state.employeeNumber);
	}
	if (state.timestamp) {
		filename.push(state.timestamp);
	}
	filename.push(state.target);

	return `${state.prefix}/${filename.join('_')}.${state.ext}`;
}

export const RenameDialog = ({
	sourceKey,
	type,
	onClose,
}: Props) => {
	const [state, setState] = React.useState<State>({
		busy: false,
		target: '',
		...parseSourceKey(type, sourceKey),
	});
	const [processing, setProcessing] = useState<ProcessState>();

	// useEffect(() => {
	// 	if (state.target) {
	// 		const unsubscribe = triggersProvider.subscribe(
	// 			{ id: { eq: state.target } },
	// 			{
	// 				onCreate: (data) => setProcessing(s => ({ ...s, status: data.status as TriggerStatus, progress: data.progress })),
	// 				onUpdate: (data) => setProcessing(s => ({ ...s, status: data.status as TriggerStatus, progress: data.progress })),
	// 				onDelete: () => setProcessing(({ ...initialProcessState, error: new Error('The operation has been deleted. Please try again') })),
	// 			}
	// 		)
	// 		return () => unsubscribe();
	// 	}
	// }, [state.target]);

	const handleContinue = async () => {
		setState((s) => ({ ...s, busy: true, error: undefined }));
		try {
			const payload: MoveFilesTriggerPayload = {
				data: [{
					source: sourceKey.replace(ThumbnailToken, ''),
					target: getTarget(type, state),
				}]
			}
			console.log('Move Payload', payload);
			// TODO: Write GraphQL Trigger to start move processing

			// const dt = new Date().toISOString();
			// await triggersProvider.createTrigger({
			// 	id: state.target,
			// 	type: TriggerTypes.MoveFiles,
			// 	status: TriggerStatusTypes.Pending,
			// 	progress: '...',
			// 	createdAt: dt,
			// 	updatedAt: dt,
			// 	payload: JSON.stringify({
			// 		folder,
			// 		filename,
			// 	}),
			// })

			// For Testing
			setState((s) => ({ ...s, busy: false })); // onClose();
		} catch (error) {
			setState((s) => ({ ...s, busy: false, error }));
		}
	};

	const isTargetValid = /^[a-zA-Z][a-zA-Z0-9]*$/.test(state.target);

	return (
		<Dialog
			isOpen={true}
			title={type === 'file' ? 'Rename File' : 'Rename Folder'}
			onClose={onClose}
			cancelAction={{
				text: 'Cancel',
				color: 'inherit',
				onClick: onClose,
			}}
			positiveAction={{
				text: 'Continue',
				color: 'secondary',
				onClick: handleContinue,
				isDisabled: isTargetValid !== true,
			}}
		>
			<Box sx={theme => ({
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				padding: theme.spacing(2),
			})}>
				<ErrorMessage
					error={state.error}
					onRetry={() =>
						setState((s) => ({ ...s, error: undefined }))
					}
				/>
				{state.busy && <Loading />}
				{!state.busy && !state.error && (
					<>
						<TextField
							label={type === 'file' ? 'Current File Name' : 'Current Folder Name'}
							aria-label="Input"
							title=""
							defaultValue={state.name}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
							disabled={true}
							sx={theme => ({ my: theme.spacing(2) })}
						/>
						<TextField
							label="New Name"
							aria-label="Input"
							title=""
							value={state.target || ''}
							onChange={(e) =>
								setState((s) => ({
									...s,
									target: e.target.value || '',
								}))
							}
							variant="outlined"
							margin="dense"
							fullWidth
							InputLabelProps={{ shrink: true }}
							error={!isTargetValid}
							helperText={'Must start with a letter and only contain letters and numbers. No punctuation please.'}
						/>
					</>
				)}
			</Box>
		</Dialog>
	);
};
