import { uploadData } from 'aws-amplify/storage';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { createFileData, FileData } from './utils';

export type UseUploadProps = {
	namePrefix: string;
	folder: string;
	onUploadComplete: (files: FileData[]) => void;
	onError: (error: any) => void;
};

export type UploadStatus = 'idle' | 'uploading' | 'complete' | 'error';

export type UseUploadHook = {
	getRootProps: any;
	getInputProps: any;
	totalUploadSize: number;
	currentUploadSize: number;
	status: UploadStatus;
	error?: any;
	reset: () => void;
};

type UploadState = {
	totalUploadSize: number;
	currentUploadSize: number;
	error?: any;
	status: UploadStatus;
	uploads: Record<string, UploadProgress>;
};

const initialState = {
	totalUploadSize: 0,
	currentUploadSize: 0,
	status: 'idle' as UploadStatus,
	uploads: {},
};

type UploadProgress = {
	id: string;
	total: number;
	current: number;
};

export const useUpload = ({
	folder,
	onUploadComplete,
	onError,
	namePrefix,
}: UseUploadProps): UseUploadHook => {
	const [state, setState] = useState<UploadState>(initialState);

// RUIZ01_2024-07-03T13-30-59_photo jul 02 2024, 11 04 56 am.jpg

	const handleProcessFile = useCallback(
		async (file: File): Promise<FileData> => {
			const data = createFileData(namePrefix, folder, file);
			await uploadData({
                key: data.key,
                data: file,
                options: {
                    // accessLevel: 'private',
					onProgress(event) {
						setState((s) => {
							const ns = { ...s };
							const obj = ns.uploads[file.name];
							if (obj) {
								obj.current = event.transferredBytes;
							}
							ns.currentUploadSize = 0;
							Object.values(ns.uploads).forEach(
								(x) => (ns.currentUploadSize += x.current)
							);
							return ns;
						});
					},
                },
            });
			return data;
		},
		[folder, namePrefix]
	);

	const onDrop = useCallback(
		async (acceptedFiles: File[]) => {
			try {
				let totalUploadSize = 0;
				const uploads: Record<string, UploadProgress> = {};
				acceptedFiles.forEach((file) => {
					totalUploadSize += file.size;
					uploads[file.name] = {
						id: file.name,
						total: file.size,
						current: 0,
					};
				});
				setState({
					...initialState,
					totalUploadSize,
					uploads,
					status: 'uploading',
				});

				const files = await Promise.all(
					acceptedFiles.map(handleProcessFile)
				);

				setTimeout(() => {
					setState((s) => ({
						...s,
						status: 'idle',
					}));
					onUploadComplete(files);
				}, 1000);

			} catch (error) {
				console.error(error);
				setState((s) => ({
					...s,
					status: 'error',
					error,
				}));
				onError(error);
			}
		},
		[handleProcessFile, onError, onUploadComplete]
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		noKeyboard: true,
	});

	return {
		getRootProps,
		getInputProps,
		totalUploadSize: state.totalUploadSize,
		currentUploadSize: state.currentUploadSize,
		status: state.status,
		error: state.error,
		reset: () => setState(initialState),
	};
};
