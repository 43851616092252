import { Box, ListItemButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-use';
import { useAppContext } from '../../contexts/AppContext';
import { ContactViewData, LookupTypes } from '../../core';
import {
	AutoCompleteLookup,
	EmployeeDropDown,
	QueryPage,
	QueryStatus,
	SearchField,
} from '../common';
import { ContactCardContent } from '../contacts/ContactCardContent';
import { useRecentItems } from '../recent';
import useQuery from '../useQuery';

type Props = {
	onNavigate: (url: string) => void;
	show: boolean;
};

export const ContactList = ({ onNavigate, show }: Props) => {
	const { api, isMobileLayout } = useAppContext();
	const theme = useTheme();
	const location = useLocation();
	const { addRecentContact } = useRecentItems();

	const [loaded, setLoaded] = useState(false);
	const [search, setSearch] = useState('');
	const [marketer, setMarketer] = useState<string | null>(null);
	const [typeId, setType] = useState<number | undefined>();

	const query = useQuery<ContactViewData>({
		queryCallback: (limit: number, offset: number) =>
			api.contact.query({
				search,
				typeId,
				marketer,
				limit,
				offset,
				companies: true,
			}),
		queryCountCallback: () => api.contact.count({ search, typeId }),
		shouldExecute: () => loaded,
		arguments: [search, typeId, marketer, loaded],
		limit: 50,
		delay: 500,
	});

	useEffect(() => {
		if (show) {
			setLoaded(true);
		}
	}, [show]);

	const handleOpenContact = (contact: ContactViewData) => {
		const resource =
			contact.ContactType === 'Company' ? 'companies' : 'contacts';
		const url = `/${resource}/${contact.ContactID}`;
		addRecentContact({
			id: contact.ContactID,
			name: contact.fullname,
			contactType: contact.ContactType,
			lookupType: contact.ContactType,
		});
		onNavigate(url);
	};

	if (!show) return null;

	console.log('Contact Results', query.results);

	return (
		<Box
			data-component="company-list"
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				overflow: 'hidden',
			}}
		>
			<Box sx={theme => ({
				display: 'flex',
				padding: theme.spacing(0),
			})}>
				<SearchField
					placeholder="Search by name"
					text={search}
					onChange={setSearch}
				/>
			</Box>
			<Box sx={theme => ({
				display: 'flex',
				padding: theme.spacing(0, 1),
			})}>
				<AutoCompleteLookup
					placeholder="Contact Type"
					lookupType={LookupTypes.ContactType}
					value={typeId || null}
					onChange={(value) => setType(value || undefined)}
				/>
			</Box>
			<Box sx={theme => ({
				display: 'flex',
				padding: theme.spacing(0, 1),
			})}>
				<EmployeeDropDown
					placeholder="Marketer"
					filter={(employees) => employees}
					value={marketer}
					onChange={(employeeNumber) => setMarketer(employeeNumber)}
				/>
			</Box>
			{isMobileLayout && (
				<QueryPage queryResult={query} />
			)}
			<QueryStatus
				status={query.status}
				idle="Enter your search criteria to see results"
				noresults="No results match your search criteria"
				scrollable
				error={query.error}
				sx={{ padding: theme.spacing(1) }}
			>
				<List>
					{query.results.map((contact, index) => (
						<ListItemButton
							key={contact.ContactID}
							selected={location.pathname?.includes(
								`contacts/${contact.ContactID}`
							)}
							onClick={() => handleOpenContact(contact)}
						>
							<ListItem>
								<ContactCardContent contact={contact} />
							</ListItem>
						</ListItemButton>
					))}
				</List>
				{isMobileLayout && (
					<div style={{ marginBottom: '200px' }}>&nbsp;</div>
				)}
			</QueryStatus>
			{!isMobileLayout && (
				<QueryPage queryResult={query} />
			)}
		</Box>
	);
};
