/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./types";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateTriggers = /* GraphQL */ `subscription OnCreateTriggers($filter: ModelSubscriptionTriggersFilterInput) {
  onCreateTriggers(filter: $filter) {
    id
    type
    status
    progress
    createdAt
    updatedAt
    deleteAfter
    payload
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTriggersSubscriptionVariables,
  APITypes.OnCreateTriggersSubscription
>;
export const onUpdateTriggers = /* GraphQL */ `subscription OnUpdateTriggers($filter: ModelSubscriptionTriggersFilterInput) {
  onUpdateTriggers(filter: $filter) {
    id
    type
    status
    progress
    createdAt
    updatedAt
    deleteAfter
    payload
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTriggersSubscriptionVariables,
  APITypes.OnUpdateTriggersSubscription
>;
export const onDeleteTriggers = /* GraphQL */ `subscription OnDeleteTriggers($filter: ModelSubscriptionTriggersFilterInput) {
  onDeleteTriggers(filter: $filter) {
    id
    type
    status
    progress
    createdAt
    updatedAt
    deleteAfter
    payload
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTriggersSubscriptionVariables,
  APITypes.OnDeleteTriggersSubscription
>;
