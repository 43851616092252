import React from 'react';

// Components
import { JobSummary } from '../common/JobSummary';
import { useAppContext } from '../../contexts/AppContext';

export type Props = {
	companyId: number;
};

export const CompanyJobSummary = ({ companyId }: Props) => {
	const { api } = useAppContext();

	return <JobSummary id={companyId} title="Summary Data" execute={api.company.queryJobSummary} />;
};
