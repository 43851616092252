export const cleanFileKey = (input?: string | undefined) => {
    if (!input) return '';

    let output = input
        .trim()
        .replace(/\s{2,}/g, ' ')
        .replace(/[,#!$%^&*;:{}=\-_`~()]/g, '-')
        .replace(/\s/g, '-')
        .replace(/-{2,}/g, '-')

    if (output.endsWith('-')) {
        output = output.slice(0, -1);
    }

    console.log('S3FileBrowserProvider Clean File Key', { input, output });
    return output;
};