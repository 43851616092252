import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { JobWatchListData, JobWatchListItem } from '../core';

interface WatchListState {
	items: Partial<JobWatchListItem>[];
}

const initialState: WatchListState = {
	items: [],
};

export const watchlist = createSlice({
	name: 'watchlist',
	initialState,
	reducers: {
		loadWatchListItems: (state, action: PayloadAction<JobWatchListItem[]>) => {
			console.log('Load Watch List Items', action.payload);
			state.items = action.payload;
		},
		addWatchListItem: (state, action: PayloadAction<JobWatchListData>) => {
			const data = action.payload;
			const index = state.items.findIndex(x => x.JobID === data.JobID);
			if (index === -1) {
				state.items.push({
					JobID: data.JobID,
					EmployeeNumber: data.EmployeeNumber,
				});
			}
		},
		removeWatchListItem: (state, action: PayloadAction<JobWatchListData>) => {
			const data = action.payload;
			const index = state.items.findIndex(x => x.JobID === data.JobID);
			if (index !== -1) {
				state.items.splice(index, 1);
			}

		},
	},
});

export const { loadWatchListItems, addWatchListItem, removeWatchListItem } = watchlist.actions;

export const selectWatchListItems = (state: RootState) => state.watchlist.items;

export default watchlist.reducer;
