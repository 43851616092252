import {
	mdiAccountCowboyHat,
	mdiAccountMultiple,
	mdiCalendarMultipleCheck,
	mdiCurrencyUsd,
	mdiHomeCityOutline,
	mdiCalendarCheck,
	mdiCameraOutline,
	mdiFormTextbox,
	mdiNoteOutline,
	mdiPaperclip,
} from '@mdi/js';
import { ToolbarButton } from "../../common/ToolbarButton";
import Icon from '@mdi/react';
import { Box, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { useAppContext } from '../../../contexts/AppContext';

export type NavSectionBarProps = {
	onClose: () => void;
	transparent?: boolean;
	taskCount: number;
	noteCount: number;
	contactCount: number;
	imageCount: number;
	fileCount: number;
	onRelatedItemClick: (content: string) => void;
};

export const NavSectionBar = ({
	onClose,
	transparent = false,
	onRelatedItemClick,
	taskCount,
	noteCount,
	contactCount,
	imageCount,
	fileCount,
}: NavSectionBarProps) => {
	const theme = useTheme();
	const { isWideLayout, isMobileLayout, isNarrowLayout } = useAppContext();

	const navigate = (key: string) => {
		const element = document.getElementById(key);
		if (element) {
			if (isMobileLayout) {
				onClose();
			}
			setTimeout(() => {
				element.scrollIntoView(true);
				if (window) {
					window.scrollTo({
						top: 0,
						behavior: 'smooth',
					});
				}
			});
		}
	};

	return (
		<Toolbar
			sx={{
				display: 'flex',
				flexDirection: 'row',
				flexGrow: 1,
				flexWrap: 'wrap',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: transparent === true ? 'transparent' : theme.palette.grey[300],
				color: transparent === true ? 'initial' : theme.palette.grey[900],
				padding: theme.spacing(1),
			}}
			disableGutters
		>
			{(!!isWideLayout || isNarrowLayout) && (
				<span style={{ flex: 1 }} />
			)}
			<Box sx={{ display: 'block'}}>
				<Tooltip title="Job Information">
					<IconButton
						size="medium"
						aria-label="Job Information"
						onClick={() => navigate('lossdescription')}
					>
						<Icon path={mdiHomeCityOutline} size={1} />
					</IconButton>
				</Tooltip>
				<Tooltip title="Job Roles">
					<IconButton
						size="medium"
						aria-label="Job Roles"
						onClick={() => navigate('jobroles')}
					>
						<Icon path={mdiAccountCowboyHat} size={1} />
					</IconButton>
				</Tooltip>
				<Tooltip title="Referral / Stakeholders">
					<IconButton
						size="medium"
						aria-label="Referral / Stakeholders"
						onClick={() => navigate('referralstakeholders')}
					>
						<Icon path={mdiAccountMultiple} size={1} />
					</IconButton>
				</Tooltip>
				<Tooltip title="Job Status">
					<IconButton
						size="medium"
						aria-label="Job Status"
						onClick={() => navigate('jobstatus')}
					>
						<Icon path={mdiCalendarMultipleCheck} size={1} />
					</IconButton>
				</Tooltip>
				<Tooltip title="Accounting Information">
					<IconButton
						size="medium"
						aria-label="Accounting Information"
						onClick={() => navigate('accountinginformation')}
					>
						<Icon path={mdiCurrencyUsd} size={1} />
					</IconButton>
				</Tooltip>
			</Box>
			<span style={{ flex: 1 }} />
			<Box sx={{
				color: transparent === true ? theme.palette.grey[900] : 'initial',
				pb: isNarrowLayout ? theme.spacing(2) : undefined,
				borderTop: isNarrowLayout ? '1px solid black' : undefined,
				marginTop: theme.spacing(1),
			}}>
				<ToolbarButton
					title="Tasks"
					tooltip="Job Tasks"
					count={taskCount}
					iconPath={mdiCalendarCheck}
					onClick={() => onRelatedItemClick('Tasks')}
					color={transparent === true ? undefined : theme.palette.primary.main}
				/>
				<ToolbarButton
					title="Notes"
					tooltip="Job Notes"
					count={noteCount}
					iconPath={mdiNoteOutline}
					onClick={() => onRelatedItemClick('Notes')}
					color={transparent === true ? undefined : theme.palette.primary.main}
				/>
				<ToolbarButton
					title="Contacts"
					tooltip="Contacts"
					count={contactCount}
					iconPath={mdiAccountMultiple}
					onClick={() => onRelatedItemClick('Contacts')}
					color={transparent === true ? undefined : theme.palette.primary.main}
				/>
				<ToolbarButton
					title="Images"
					tooltip="Images"
					count={imageCount}
					iconPath={mdiCameraOutline}
					onClick={() => onRelatedItemClick('Images')}
					color={transparent === true ? undefined : theme.palette.primary.main}
				/>
				<ToolbarButton
					title="Files"
					tooltip="Files"
					count={fileCount}
					iconPath={mdiPaperclip}
					onClick={() => onRelatedItemClick('Files')}
					color={transparent === true ? undefined : theme.palette.primary.main}
				/>
				<ToolbarButton
					title="Other"
					tooltip="Generate Forms &amp; Custom Fields"
					count={0}
					iconPath={mdiFormTextbox}
					onClick={() => onRelatedItemClick('Other')}
					color={transparent === true ? undefined : theme.palette.primary.main}
				/>
			</Box>
		</Toolbar>
	);
};
