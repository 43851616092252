import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

// Icons
import Icon from '@mdi/react';
import {
	mdiDotsVertical,
	mdiDownloadCircleOutline,
	mdiFormTextbox,
	mdiTrashCanOutline,
} from '@mdi/js';
import { FileOpenedEvent, S3Object } from './types';
import { formatDateTime, ThumbnailToken } from '../../core';
import { getFileIcon } from './utils';
import { useS3FileBrowserContext } from './S3FileBrowserContext';
import { ListItemButton } from '@mui/material';

export type Props = {
	readOnly?: boolean;
	canDelete?: boolean;
	file: S3Object;
	onFileOpen?: FileOpenedEvent;
};

export const FileListItem = ({
	file,
	onFileOpen,
	readOnly,
	canDelete,
}: Props) => {
	const icon = getFileIcon(file.key);
	const anchorRef = React.useRef<HTMLButtonElement>(null);
	const [open, setOpen] = React.useState(false);

	const { onRemoveFile, onDownloadFile, onRenameFile } =
		useS3FileBrowserContext();

	const handleItemClick = () => {
		if (onFileOpen) onFileOpen(file);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: MouseEvent | TouchEvent) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	return (
		<>

			<ListItem
				secondaryAction={(
					<Tooltip title="Open for more options...">
						<IconButton
							ref={anchorRef}
							size="small"
							aria-controls={
								open ? 'split-button-menu' : undefined
							}
							aria-expanded={open ? 'true' : undefined}
							aria-label="select merge strategy"
							aria-haspopup="menu"
							onClick={handleToggle}
							sx={theme => ({ mr: theme.spacing(2)})}
						>
							<Icon path={mdiDotsVertical} size={1} />
						</IconButton>
					</Tooltip>
				)}
			>
				<ListItemButton onClick={handleItemClick} sx={theme => ({ px: theme.spacing(1)})}>
					<ListItemIcon style={{ minWidth: '35px'}}>
						<Icon path={icon} size={0.75} />
					</ListItemIcon>
					<Tooltip title="Open File">
						<ListItemText
							primary={file.name.replaceAll(ThumbnailToken, '')}
							secondary={formatDateTime(file.lastModified)}
							style={{
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								minWidth: '85%',
								maxWidth: '85%',
							}}
							primaryTypographyProps={{
								style: {
									fontSize: '13px',
								},
							}}
							secondaryTypographyProps={{
								style: {
									fontSize: '11px',
								},
							}}
						/>
					</Tooltip>
				</ListItemButton>
			</ListItem>

			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				style={{ zIndex: 99999 }}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom'
									? 'center top'
									: 'center bottom',
						}}
					>
						<Paper style={{ zIndex: 99999 }}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu">
									<MenuItem
										onClick={() => onDownloadFile(file)}
									>
										<ListItemIcon
											style={{ minWidth: '35px' }}
										>
											<Icon
												path={mdiDownloadCircleOutline}
												size={1}
											/>
										</ListItemIcon>
										Download
									</MenuItem>
									<MenuItem
										onClick={() => onRenameFile(file)}
										disabled={readOnly}
									>
										<ListItemIcon
											style={{ minWidth: '35px' }}
										>
											<Icon
												path={mdiFormTextbox}
												size={1}
											/>
										</ListItemIcon>
										Rename
									</MenuItem>
									<MenuItem
										onClick={() => onRemoveFile(file)}
										disabled={!canDelete || readOnly}
									>
										<ListItemIcon
											style={{ minWidth: '35px' }}
										>
											<Icon
												path={mdiTrashCanOutline}
												size={1}
											/>
										</ListItemIcon>
										Remove
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};
