import { generateClient } from 'aws-amplify/api';
import { createTriggers, CreateTriggersInput, deleteTriggers, DeleteTriggersInput, getTriggers, GetTriggersQueryVariables, ModelTriggersFilterInput, onCreateTriggers, OnCreateTriggersSubscriptionVariables, onDeleteTriggers, onUpdateTriggers, Triggers, updateTriggers, UpdateTriggersInput } from '../../core/graphql';
import { SubscribeCallbackFunctionOptions, UnsubscribeFunction } from './types';

export class GraphQLTriggersProvider {
    private client: any;

    constructor() {
        this.ensureClientGenerated = this.ensureClientGenerated.bind(this);
        this.subscribe = this.subscribe.bind(this);
        this.getTrigger = this.getTrigger.bind(this);
        this.createTrigger = this.createTrigger.bind(this);
        this.updateTrigger = this.updateTrigger.bind(this);
        this.deleteTrigger = this.deleteTrigger.bind(this);
        // this.listMessages = this.listMessages.bind(this);
        // this.listAllTriggers = this.listAllTriggers.bind(this);
    }

    private ensureClientGenerated() {
        if (!this.client) {
            // Client can't be in constructor because Amplify.configure must be called before generateClient
            this.client = generateClient();
        }
    }

    public subscribe(
        filter: ModelTriggersFilterInput,
        options?: SubscribeCallbackFunctionOptions
    ): UnsubscribeFunction {
        this.ensureClientGenerated();

        const variables: OnCreateTriggersSubscriptionVariables = {
            filter
        };

        const createSub = this.client
            .graphql({ query: onCreateTriggers, variables })
            .subscribe({
                next: ({ data }: any) => {
                    if (data && data.onCreateTriggers) {
                        const message = data.onCreateTriggers as Triggers;
                        if (options?.onCreate) {
                            options.onCreate(message);
                        }
                        console.debug('GraphQLTriggersProvider:createSub', { message });
                    }
                },
                error: (error: any) => console.error('GraphQLTriggersProvider:subscribe:createSub', error)
            });

        const updateSub = this.client
            .graphql({ query: onUpdateTriggers, variables })
            .subscribe({
                next: ({ data }: any) => {
                    if (data && data.onUpdateTriggers) {
                        const message = data.onUpdateTriggers as Triggers;
                        if (options?.onUpdate) {
                            options.onUpdate(message);
                        }
                        console.debug('GraphQLTriggersProvider:updateSub', { message });
                    }
                },
                error: (error: any) => console.error('GraphQLTriggersProvider:subscribe:updateSub', error)
            });

        const deleteSub = this.client
            .graphql({ query: onDeleteTriggers, variables })
            .subscribe({
                next: ({ data }: any) => {
                    if (data && data.onDeleteTriggers) {
                        const message = data.onDeleteTriggers as Triggers;
                        if (options?.onDelete) {
                            options.onDelete(message);
                        }
                        console.debug('GraphQLTriggersProvider:deleteSub', { message });
                    }
                },
                error: (error: any) => console.error('GraphQLTriggersProvider:subscribe:deleteSub', error)
            });

        return () => {
            try {
                console.debug('GraphQLTriggersProvider:unsubscribe');
                createSub.unsubscribe();
                updateSub.unsubscribe();
                deleteSub.unsubscribe();
            } catch (error) {
                console.error('GraphQLTriggersProvider:subscribe:unsubscribe', error)
            }
        };
    }

    public async getTrigger(input: GetTriggersQueryVariables): Promise<Triggers> {
        this.ensureClientGenerated();
        try {
            const request = {
                query: getTriggers,
                variables: input
            };
            console.debug('GraphQLTriggersProvider:getTriggers:request', request);
            const response = await this.client.graphql(request);
            console.debug('GraphQLTriggersProvider:getTriggers:response', response);
            return response.data.getTriggers;
        } catch (error) {
            console.error('GraphQLTriggersProvider:getTriggers:error', error);
            throw error;
        }
    }

    public async createTrigger(input: CreateTriggersInput): Promise<Triggers> {
        this.ensureClientGenerated();
        try {
            const request = {
                query: createTriggers,
                variables: { input }
            };
            console.debug('GraphQLTriggersProvider:createTriggers:request', request);
            const response = await this.client.graphql(request);
            console.debug('GraphQLTriggersProvider:createTriggers:response', response);
            if (response?.data?.createTriggers) {
                return response.data.createTriggers;
            } else {
                throw new Error('GraphQLTriggersProvider: Unexpected GraphQL Response')
            }
        } catch (error) {
            console.error('GraphQLTriggersProvider:createTriggers:error', error);
            throw error;
        }
    }

    public async updateTrigger(input: UpdateTriggersInput): Promise<Triggers> {
        this.ensureClientGenerated();
        try {
            const request = {
                query: updateTriggers,
                variables: { input }
            };
            console.debug('GraphQLTriggersProvider:updateTrigger:request', request);
            const response = await this.client.graphql(request);
            console.debug('GraphQLTriggersProvider:updateTrigger:response', response);
            if (response?.data?.updateTriggers) {
                return response.data.updateTriggers;
            } else {
                throw new Error('GraphQLTriggersProvider: Unexpected GraphQL Response')
            }
        } catch (error: any) {
            console.error('GraphQLTriggersProvider:updateTrigger:error', error);
            throw error;
        }
    }

    public async deleteTrigger(input: DeleteTriggersInput): Promise<Triggers> {
        this.ensureClientGenerated();
        try {
            const request = {
                query: deleteTriggers,
                variables: { input }
            };
            console.debug('GraphQLTriggersProvider:deleteTrigger:request', request);
            const response = await this.client.graphql(request);
            console.debug('GraphQLTriggersProvider:deleteTrigger:response', response);
            if (response?.data?.deleteTriggers) {
                return response.data.deleteTriggers;
            } else {
                throw new Error('GraphQLTriggersProvider: Unexpected GraphQL Response');
            }
        } catch (error) {
            console.error('GraphQLTriggersProvider:deleteTrigger:error', error);
            throw error;
        }
    }

    // public async listMessages(variables: ListTriggersQueryVariables): Promise<Triggers[]> {
    //     this.ensureClientGenerated();
    //     try {
    //         const request = {
    //             query: listTriggers,
    //             variables
    //         };
    //         console.debug('listTriggers', 'call', request);
    //         const response = await this.client.graphql(request);
    //         console.debug('listTriggers', 'response', response);
    //         const { items } = response.data.listTriggers;
    //         return items;
    //     } catch (error) {
    //         console.error('listTriggers', 'listTriggers', error);
    //         throw error;
    //     }
    // }

    // public async listAllTriggers(variables: ListTriggersQueryVariables, nextToken?: string | null): Promise<Triggers[]> {
    //     this.ensureClientGenerated();
    //     try {
    //         let moreItems: Triggers[] = [];
    //         const request = {
    //             query: listTriggers,
    //             variables: {
    //                 ...variables,
    //                 limit: 100,
    //                 nextToken
    //             },
    //         };
    //         console.debug('listAllTriggers', 'call', request);
    //         const response = await this.client.graphql(request);
    //         const { items = [], nextToken: continuationToken } = response.data.listTriggers;
    //         if (continuationToken) {
    //             console.debug('listAllTriggers', 'response-with-continuation-token', response);
    //             moreItems = await this.listAllTriggers(variables, continuationToken);
    //         }
    //         console.debug('listAllTriggers', 'response', response);
    //         return [...items, ...moreItems];
    //     } catch (error) {
    //         console.error('listAllTriggers', 'listMessages', error);
    //         throw error;
    //     }
    // }
}

export const triggersProvider = new GraphQLTriggersProvider();