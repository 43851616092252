import {
	mdiAws,
	mdiChevronDown,
	mdiDownloadCircleOutline,
	mdiFolderPlusOutline,
	mdiFormTextbox,
	mdiImageOutline,
	mdiReload,
	mdiTrashCanOutline,
	mdiUpload,
	mdiViewGalleryOutline,
	mdiViewListOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { orderBy } from 'lodash';
import React from 'react';

import { useAppContext } from '../../contexts/AppContext';
import { UserPermissions } from '../../core';
import { useWindowOpen } from '../useWindowOpen';
import { useS3FileBrowserContext } from './S3FileBrowserContext';
import { FolderChangedEvent, S3BrowserView, ToggleViewEvent } from './types';
import {
	getFolderPath,
	getSubFileObjects,
	isFolderEmpty,
	isSystemFolder,
} from './utils';

type Props = {
	folder: string;
	view: S3BrowserView;
	disableViewToggle?: boolean;
	disableGalleryButton?: boolean;
	onToggleView?: ToggleViewEvent;
	onChangeFolder?: FolderChangedEvent;
	readOnly?: boolean;
	canDelete?: boolean;
};

const getFolderName = (name: string, root: string): string => {
	return `${name}/` === root ? 'Job' : name;
};

export const S3Toolbar = ({
	folder,
	view,
	disableViewToggle,
	disableGalleryButton,
	onToggleView = () => { },
	onChangeFolder = () => { },
	readOnly,
	canDelete,
}: Props) => {
	const { hasPermission, region, bucket } = useAppContext();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const isAdmin = hasPermission([UserPermissions.SYSADMIN]);
	const open = useWindowOpen();
	const {
		root,
		folders: allFolders,
		files,
		onAddSubFolder,
		onRenameFolder,
		onRemoveFolder,
		onDownloadFolder,
		onOpenGallery,
		onOpenUpload,
		reload,
	} = useS3FileBrowserContext();

	if (!folder.endsWith('/')) {
		console.warn('Invalid folder path given to S3Toolbar');
	}

	const paths = getFolderPath(`${folder}`);
	const folders = allFolders.filter((x) => paths.includes(x.key));
	const current = folders.pop();
	const awsUrl = `https://s3.console.aws.amazon.com/s3/buckets/${bucket}?region=${region}&prefix=public/${folder}&showversions=false`;

	const handleOpenAWS = () => {
		open(awsUrl);
	};

	const handleOpenGallery = () => {
		if (current) {
			const galleryFiles = getSubFileObjects(files, current.key);
			onOpenGallery(galleryFiles, 0);
		}
	};

	const handleOpenUpload = () => {
		if (current) {
			onOpenUpload(current);
		}
	};

	return (
		<>
			<Toolbar
				style={{
					paddingLeft: '6px',
					paddingRight: '6px',
					minHeight: '40px',
					marginTop: '12px',
				}}
			>
				<Tooltip title="Upload to the current folder">
					<Button
						size="small"
						onClick={handleOpenUpload}
						startIcon={<Icon path={mdiUpload} size={0.85} />}
						disabled={readOnly}
					>
						Upload
					</Button>
				</Tooltip>
				{!disableGalleryButton && (
					<Tooltip title="Open Gallery">
						<Button
							size="small"
							onClick={handleOpenGallery}
							style={{ marginLeft: '12px' }}
							startIcon={
								<Icon
									path={mdiViewGalleryOutline}
									size={0.85}
								/>
							}
						>
							Gallery
						</Button>
					</Tooltip>
				)}
				{!disableViewToggle && (
					<Tooltip title="Toggle View">
						<Button
							size="small"
							onClick={onToggleView}
							style={{ marginLeft: '12px' }}
							startIcon={
								<Icon
									path={
										view === 'list'
											? mdiViewListOutline
											: mdiImageOutline
									}
									size={1}
								/>
							}
						>
							View
						</Button>
					</Tooltip>
				)}
				<span style={{ flexGrow: 1 }} />
				<Tooltip title="Refresh">
					<IconButton
						size="small"
						onClick={reload}
						style={{ marginRight: '6px' }}
					>
						<Icon path={mdiReload} size={1} />
					</IconButton>
				</Tooltip>
				{isAdmin && (
					<Tooltip title={awsUrl}>
						<IconButton
							size="small"
							onClick={handleOpenAWS}
							style={{ marginRight: '6px' }}
						>
							<Icon path={mdiAws} size={1} color="orange" />
						</IconButton>
					</Tooltip>
				)}

			</Toolbar>
			<Divider />
			<Toolbar
				style={{
					paddingLeft: '6px',
					paddingRight: '6px',
					minHeight: 'unset',
					maxHeight: 'unset',
					height: 'unset',
					flexFlow: 'row wrap',
				}}
			>
				{orderBy(folders, 'name').map((f) => (
					<Tooltip
						key={f.key}
						title={`Open ${getFolderName(f.name, root)}`}
					>
						<>
							<Button
								size="small"
								variant="text"
								onClick={() => onChangeFolder(f)}
								style={{
									minWidth: 'unset',
									padding: '5px 5px',
									whiteSpace: 'nowrap',
								}}
							>
								{getFolderName(f.name, root)}
							</Button>
							<span
								style={{
									paddingLeft: '8px',
									paddingRight: '8px',
									fontWeight: 'bold',
								}}
							>
								/
							</span>
						</>
					</Tooltip>
				))}
				{current && (
					<>
						<Tooltip
							key={current.key}
							title={`Click for more options...`}
						>
							<Button
								size="small"
								variant="text"
								aria-controls="folder-menu"
								aria-haspopup="true"
								onClick={(event) =>
									setAnchorEl(event.currentTarget)
								}
								endIcon={
									<Icon path={mdiChevronDown} size={1} />
								}
							>
								{getFolderName(current.name, root)}
							</Button>
						</Tooltip>
						<Menu
							id="folder-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={() => setAnchorEl(null)}
						>
							<MenuItem
								onClick={() => onAddSubFolder(current)}
								disabled={readOnly}
							>
								<ListItemIcon style={{ minWidth: '35px' }}>
									<Icon
										path={mdiFolderPlusOutline}
										size={1}
									/>
								</ListItemIcon>
								New Folder
							</MenuItem>
							<MenuItem onClick={() => onDownloadFolder(current)}>
								<ListItemIcon style={{ minWidth: '35px' }}>
									<Icon
										path={mdiDownloadCircleOutline}
										size={1}
									/>
								</ListItemIcon>
								Download Folder
							</MenuItem>
							{!isSystemFolder(root, current.key) && (
								<>
									<MenuItem
										onClick={() => onRenameFolder(current)}
										disabled={readOnly}
									>
										<ListItemIcon
											style={{ minWidth: '35px' }}
										>
											<Icon
												path={mdiFormTextbox}
												size={1}
											/>
										</ListItemIcon>
										Rename Folder
									</MenuItem>
									<MenuItem
										onClick={() => onRemoveFolder(current)}
										disabled={
											!canDelete ||
											readOnly ||
											!isFolderEmpty(
												files.map((x) => x.key),
												current.key
											)
										}
									>
										<ListItemIcon
											style={{ minWidth: '35px' }}
										>
											<Icon
												path={mdiTrashCanOutline}
												size={1}
											/>
										</ListItemIcon>
										Remove Folder
									</MenuItem>
								</>
							)}
						</Menu>
					</>
				)}
			</Toolbar>
			<Divider style={{ marginBottom: '12px' }} />
		</>
	);
};
