import {
	mdiAccountCowboyHat,
	mdiCalendarCheck,
	mdiDomain,
	mdiFormTextbox,
	mdiHomeCityOutline,
	mdiNoteOutline,
} from '@mdi/js';
import { Box, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { ContactData, LookupTypes } from '../../core';
import { ToolbarButton } from '../common/ToolbarButton';
import { useLookupTypes } from '../lookups';
import { useAppContext } from '../../contexts/AppContext';

type ContactTitleProps = {
	contact: ContactData | null | undefined;
	taskCount: number;
	noteCount: number;
	jobCount: number;
	companyCount: number;
	onRelatedItemClick: (content: string) => void;
};

export const ContactTitle = (props: ContactTitleProps) => {
	const { isWideLayout } = useAppContext();
	const theme = useTheme();

	const {
		contact,
		taskCount,
		noteCount,
		jobCount,
		companyCount,
		onRelatedItemClick,
	} = props;
	const [contactTypes] = useLookupTypes([LookupTypes.ContactType]);
	const type = contactTypes?.find(
		(x: any) => x.ID === contact?.ContactTypeID
	);

	if (!contact) return null;

	return (
		<>

			<AppBar
				position="relative"
				sx={theme => ({
					zIndex: theme.zIndex.drawer + 1,
					'& h6': {
						marginRight: theme.spacing(2),
					},
					transition: theme.transitions.create(['margin', 'width'], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
				})}
				color="secondary"
			>
				<Toolbar>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
					}}>
						<Box sx={theme => ({
							fontSize: theme.typography.h6.fontSize,
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							[theme.breakpoints.down('sm')]: {
								fontSize: theme.typography.body2.fontSize,
							},
							[theme.breakpoints.down('xs')]: {
								fontSize: theme.typography.caption.fontSize,
								width: '100px',
							},
						})}>
							<strong>
								{contact.LastName}, {contact.FirstName}
							</strong>
						</Box>
						{type && <Box sx={theme => ({
							fontSize: theme.typography.body2.fontSize,
							[theme.breakpoints.down('xs')]: {
								fontSize: theme.typography.caption.fontSize,
							},
						})}>{type.Name}</Box>}
					</Box>
					<span className="flex" />

					{isWideLayout && (
						<>
							<ToolbarButton
								title="Tasks"
								tooltip="Tasks"
								count={taskCount}
								iconPath={mdiCalendarCheck}
								onClick={() => onRelatedItemClick('Tasks')}
							/>

							<ToolbarButton
								title="Notes"
								tooltip="Notes"
								count={noteCount}
								iconPath={mdiNoteOutline}
								onClick={() => onRelatedItemClick('Notes')}
							/>

							<ToolbarButton
								title="Jobs"
								tooltip="Jobs"
								count={jobCount}
								iconPath={mdiHomeCityOutline}
								onClick={() => onRelatedItemClick('Jobs')}
							/>
							<ToolbarButton
								title="Companies"
								tooltip="Companies"
								count={companyCount}
								iconPath={mdiDomain}
								onClick={() => onRelatedItemClick('Companies')}
							/>
							<ToolbarButton
								title="Fields"
								tooltip="Fields"
								count={0}
								iconPath={mdiFormTextbox}
								onClick={() => onRelatedItemClick('Fields')}
							/>
							<ToolbarButton
								title="Sales"
								tooltip="Sales"
								count={0}
								iconPath={mdiAccountCowboyHat}
								onClick={() => onRelatedItemClick('SalesReps')}
							/>
						</>
					)}

				</Toolbar>
			</AppBar>
			{!isWideLayout && (
				<Toolbar
					sx={{
						display: 'flex',
						flexDirection: 'row',
						flexGrow: 1,
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: 'transparent',
					}}
					disableGutters
				>
					<span style={{ flex: 1 }} />
					<ToolbarButton
						title="Tasks"
						tooltip="Tasks"
						count={taskCount}
						iconPath={mdiCalendarCheck}
						onClick={() => onRelatedItemClick('Tasks')}
						color={theme.palette.primary.main}
					/>

					<ToolbarButton
						title="Notes"
						tooltip="Notes"
						count={noteCount}
						iconPath={mdiNoteOutline}
						onClick={() => onRelatedItemClick('Notes')}
						color={theme.palette.primary.main}
					/>

					<ToolbarButton
						title="Jobs"
						tooltip="Jobs"
						count={jobCount}
						iconPath={mdiHomeCityOutline}
						onClick={() => onRelatedItemClick('Jobs')}
						color={theme.palette.primary.main}
					/>
					<ToolbarButton
						title="Companies"
						tooltip="Companies"
						count={companyCount}
						iconPath={mdiDomain}
						onClick={() => onRelatedItemClick('Companies')}
						color={theme.palette.primary.main}
					/>
					<ToolbarButton
						title="Fields"
						tooltip="Fields"
						count={0}
						iconPath={mdiFormTextbox}
						onClick={() => onRelatedItemClick('Fields')}
						color={theme.palette.primary.main}
					/>
					<ToolbarButton
						title="Sales"
						tooltip="Sales"
						count={0}
						iconPath={mdiAccountCowboyHat}
						onClick={() => onRelatedItemClick('SalesReps')}
						color={theme.palette.primary.main}
					/>
				</Toolbar>
			)}
		</>
	);
};
