/* eslint-disable no-nested-ternary */
/**
 * A convenience object to help with dates
 */
export class DateTime {
	public static TotalMinutesInOneDay = 24 * 60;

	public static TotalSecondsInOneDay = 24 * 60 * 60;

	public readonly value: Date;

	public readonly year: string;

	public readonly month: string;

	public readonly day: string;

	public readonly hour: string;

	public readonly minutes: string;

	/**
	 * Builds a new DateTime object
	 * @param value The value to parse as a javascript date
	 */
	constructor(value?: string | Date | number | null | undefined) {
		this.value = value ? new Date(value) : new Date();
		this.year = `${this.value.getFullYear()}`;
		this.month = `${this.value.getMonth() + 1}`.padStart(2, '0');
		this.day = `${this.value.getDate()}`.padStart(2, '0');
		this.hour = `${this.value.getHours()}`.padStart(2, '0');
		this.minutes = `${this.value.getMinutes()}`.padStart(2, '0');
	}

	public static isValidDate(value?: Date | string | null): boolean {
		if (!value) {
			return false;
		}
		return new Date(value).toString() !== 'Invalid Date';
	}

	/**
	 * Is this DateTime object a valid date?
	 */
	public isValid(): boolean {
		return DateTime.isValidDate(this.toDate());
	}

	/**
	 * Build a new DateTime object from milliseconds since January 1, 1970 00:00:00 UTC
	 * @param value the number of milliseconds
	 * @returns A new DateTime object
	 */
	public static fromEPOCHMilliseconds(value: number): DateTime {
		const dt = new Date(value);
		return new DateTime(dt);
	}

	/**
	 * Build a new DateTime object from seconds since January 1, 1970 00:00:00 UTC
	 * @param value the number of seconds
	 * @returns A new DateTime object
	 */
	public static fromEPOCHSeconds(value: number): DateTime {
		const dt = new Date(value * 1000);
		return new DateTime(dt);
	}

	/**
	 * Converts this DateTime object into a new javascript date
	 * @returns A new javascript date`
	 */
	public toDate(): Date {
		return this.value;
	}

	/**
	 * Converts this DateTime object into a ISO String
	 * @returns 1970-01-01T00:00:00.000Z; A formatted ISO String
	 */
	public toISOString(): string {
		return this.toDate().toISOString();
	}

	/**
	 * Converts this DateTime object into milliseconds since January 1, 1970 00:00:00 UTC
	 * @returns A big number
	 */
	public toEPOCHMilliseconds(): number {
		return this.toDate().getMilliseconds();
	}

	/**
	 * Converts this DateTime object into seconds since January 1, 1970 00:00:00 UTC
	 * @returns A big number
	 */
	public toEPOCHSeconds(): number {
		return this.toDate().getMilliseconds() / 1000;
	}

	public toJson() {
		return {
			year: this.value.getFullYear(),
			month: this.value.getMonth() + 1,
			day: this.value.getDate(),
			hour: this.value.getHours(),
			minutes: this.value.getMinutes(),
			seconds: this.value.getSeconds(),
			offset: this.value.getTimezoneOffset(),
			timeZoneShort: this.value
				.toLocaleDateString('en-US', {
					day: '2-digit',
					timeZoneName: 'short',
				})
				.slice(4),
			timeZoneLong: this.value
				.toLocaleDateString('en-US', {
					day: '2-digit',
					timeZoneName: 'long',
				})
				.slice(4),
			iso: this.value.toISOString(),
		};
	}

	/**
	 * Converts this DateTime object into a JSON string
	 * @returns A JSON string
	 */
	public toJsonString(): string {
		return JSON.stringify(this.toJson(), null, '\t');
	}

	/**
	 * Returns a new DateTime object as of the beginning of the day
	 * @returns A new DateTime object as of the beginning of the day
	 */
	public toStartOfDay(): DateTime {
		const dt = new Date(this.value);
		dt.setHours(0);
		dt.setMinutes(0);
		dt.setSeconds(0);
		dt.setMilliseconds(0);
		return new DateTime(dt);
	}

	/**
	 * Returns a new DateTime object as of the end of the day
	 * @returns A new DateTime object as of the end of the day
	 */
	public toEndOfDay(): DateTime {
		const dt = new Date(this.value);
		dt.setHours(23);
		dt.setMinutes(59);
		dt.setSeconds(59);
		dt.setMilliseconds(0);
		return new DateTime(dt);
	}

	/**
	 * Returns start and end DateTime objects that represent the start and end of the day
	 * @returns [The beginning of the day, The end of the day]
	 */
	public toDayRange(): [DateTime, DateTime] {
		return [this.toStartOfDay(), this.toEndOfDay()];
	}

	/**
	 * Returns start and end DateTime objects that represent the start and end of the day
	 * @returns [The beginning of the day, The end of the day]
	 */
	public static expandDayRange(
		start: DateTime,
		end: DateTime,
		byNumberOfDays: number
	): [DateTime, DateTime] {
		return [start.addDays(-byNumberOfDays), end.addDays(byNumberOfDays)];
	}

	/**
	 * Converts the date part of this DateTime object into a formatted string
	 * @returns 1970-01-01
	 */
	public toFormattedShortDateYearFirst(separator = '-'): string {
		return `${this.year}${separator}${this.month}${separator}${this.day}`;
	}

	/**
	 * Converts the date part of this DateTime object into a formatted string
	 * @returns 01-01-1970
	 */
	public toFormattedShortDateMonthFirst(separator = '-'): string {
		return `${this.month}${separator}${this.day}${separator}${this.year}`;
	}

	/**
	 * Converts the time part of this DateTime object into a formatted string
	 * @returns 12:00 AM
	 */
	public toFormattedShortTime(): string {
		const [timeString, amPm] = this.toDate()
			.toLocaleTimeString() // 11:15:00 AM
			.split(' ');
		const time = timeString.split(':');
		return `${time[0]}:${time[1]} ${amPm}`;
	}

	/**
	 * Converts the time part of this DateTime object into a formatted string
	 * @returns 12 AM
	 */
	public toFormattedShortTimeHourOnly(): string {
		const [timeString, amPm] = this.toDate()
			.toLocaleTimeString() // 11:15:00 AM
			.split(' ');
		const time = timeString.split(':');
		return `${time[0]} ${amPm}`;
	}

	/**
	 * Converts this DateTime object into a formatted string
	 * @returns 1970-01-01 12:00 AM
	 */
	public toFormattedShortDateTimeYearFirst(separator = '-'): string {
		const d = this.toFormattedShortDateYearFirst(separator);
		const t = this.toFormattedShortTime();
		return `${d} ${t}`;
	}

	/**
	 * Converts this DateTime object into a formatted string
	 * @returns 01-01-1970 12:00 AM
	 */
	public toFormattedShortDateTimeMonthFirst(separator = '-'): string {
		const d = this.toFormattedShortDateMonthFirst(separator);
		const t = this.toFormattedShortTime();
		return `${d} ${t}`;
	}

	/**
	 * Gets the total number of minutes since midnight on the given date.
	 * @returns The number of minutes since midnight
	 */
	public static getTotalMinutesFromMidnight(dt: Date) {
		const hours = dt.getHours();
		const mins = dt.getMinutes();
		return hours * 60 + mins;
	}

	/**
	 * Gets the total number of minutes between two dates.
	 * @returns The number of minutes between two dates
	 */
	public static getMinutesBetweenDates(start: Date, end: Date): number {
		const msInMinute = 60 * 1000;
		return Math.round(
			Math.abs(end.getTime() - start.getTime()) / msInMinute
		);
	}

	/**
	 * Returns a new DateTime object with months added (or subtracted)
	 * @returns A new DateTime object with months added (or subtracted).
	 */
	public addMonths(months: number): DateTime {
		const dt = new Date(this.value);
		dt.setMonth(dt.getMonth() + months);
		return new DateTime(dt);
	}

	/**
	 * Adds months to the given date
	 * @returns A new date with months added (or subtracted).
	 */
	public static addMonths(input: Date, months: number): Date {
		const dt = new Date(input);
		dt.setMonth(dt.getMonth() + months);
		return dt;
	}

	/**
	 * Returns a new DateTime object with days added (or subtracted)
	 * @returns A new DateTime object with days added (or subtracted).
	 */
	public addDays(days: number): DateTime {
		const dt = new Date(this.value);
		dt.setDate(dt.getDate() + days);
		return new DateTime(dt);
	}

	/**
	 * Adds days to the given date
	 * @returns A new date with days added (or subtracted).
	 */
	public static addDays(input: Date, days: number): Date {
		const dt = new Date(input);
		dt.setDate(dt.getDate() + days);
		return dt;
	}

	/**
	 * Returns a new DateTime object with minutes added (or subtracted)
	 * @returns A new DateTime object with minutes added (or subtracted).
	 */
	public addMinutes(minutes: number): DateTime {
		const dt = new Date(this.value);
		dt.setMinutes(dt.getMinutes() + minutes);
		return new DateTime(dt);
	}

	/**
	 * Adds minutes to the given date
	 * @returns A new date with minutes added (or subtracted).
	 */
	public static addMinutes(input: Date, minutes: number): Date {
		const dt = new Date(input);
		dt.setMinutes(dt.getMinutes() + minutes);
		return dt;
	}

	/**
	 * Returns a new DateTime object with minutes added (or subtracted)
	 * @returns A new DateTime object with minutes added (or subtracted).
	 */
	public addSeconds(seconds: number): DateTime {
		const dt = new Date(this.value);
		dt.setSeconds(dt.getSeconds() + seconds);
		return new DateTime(dt);
	}

	/**
	 * Adds minutes to the given date
	 * @returns A new date with minutes added (or subtracted).
	 */
	public static addSeconds(input: Date, seconds: number): Date {
		const dt = new Date(input);
		dt.setSeconds(dt.getSeconds() + seconds);
		return dt;
	}

	/**
	 * Gets the total number of days in a given month
	 * @returns 27, 28, 30, or 31
	 */
	public static getNumberOfDaysInMonth = (input: Date): number => {
		const nextMonth = DateTime.addMonths(input, 1);
		const lastDay = DateTime.addDays(nextMonth, -1);
		return lastDay.getDate();
	};

	/**
	 * Checks if a date is equal to or in between two dates
	 * @param value The date to check
	 * @param start The start date of the range
	 * @param end The end date of the range
	 */
	public static isInRange = (
		value: Date,
		start: Date,
		end: Date
	): 'before' | 'after' | 'inrange' => {
		const v = value.getTime();
		const s = start.getTime();
		const e = end.getTime();

		if (e < v) {
			return 'before';
		}
		if (s > v) {
			return 'after';
		}
		return 'inrange';
	};

	/**
	 * Checks if a date range overlaps with another date range
	 * @param sourceStart The date to check
	 * @param sourceEnd The date to check
	 * @param targetStart The start date of the range
	 * @param targetEnd The end date of the range
	 */
	public static hasOverlap = (
		sourceStart: Date,
		sourceEnd: Date,
		targetStart: Date,
		targetEnd: Date
	): boolean => {
		if (
			DateTime.isInRange(sourceStart, targetStart, targetEnd) ===
			'inrange'
		) {
			return true;
		}
		if (
			DateTime.isInRange(sourceEnd, targetStart, targetEnd) === 'inrange'
		) {
			return true;
		}
		return false;
	};

	public static getDayOrdinal(date: Date): string {
		const day = date.getDate();
		return (
			day +
			(day % 10 === 1 && day !== 11
				? 'st'
				: day % 10 === 2 && day !== 12
					? 'nd'
					: day % 10 === 3 && day !== 13
						? 'rd'
						: 'th')
		);
	}

	public static getMonthShort(dt: Date): string {
		// iOS = Nov
		// Android = Tue Nov 22 00:00:00 2022
		// STUPID!
		const val = dt.toLocaleString('default', { month: 'short' });

		// Handle Android
		const s = val.split(' ');
		if (s.length === 1) {
			return val;
		}
		if (s.length >= 1) {
			return s[1];
		}
		return '??';
	}

	public static getWeekday(dt: Date): string {
		// iOS = Tue
		// Android = Tue Nov 22 00:00:00 2022
		// STUPID!
		const val = dt
			.toLocaleString('default', { weekday: 'short' })
			.replace(',', '');

		// Handle Android
		const s = val.split(' ');
		if (s.length === 1) {
			return val;
		}
		if (s.length >= 0) {
			return s[0];
		}
		return '??';
	}


	public static getMonthLong(dt: Date): string {
		const val = dt.toLocaleString('default', { month: 'long' });
		return val;
	}

	public static getWeekdayLong(dt: Date): string {
		const val = dt.toLocaleString('default', { weekday: 'long' });
		return val;
	}

	public static setTime(
		dt: Date | string | number,
		time?: Date | string | number
	): Date {
		const d = new Date(dt);
		if (!time) {
			return d;
		}

		const t = new Date(time);
		d.setHours(t.getHours());
		d.setMinutes(t.getMinutes());
		d.setSeconds(0);
		d.setMilliseconds(0);
		return d;
	}
}
