import TextField from '@mui/material/TextField';
import { ContactData } from '../../core';
import { Container } from '../common';

const fields = [
	{ key: 'UserDefined1', label: 'User Defined 1' },
	{ key: 'UserDefined2', label: 'User Defined 2' },
	{ key: 'UserDefined3', label: 'User Defined 3' },
	{ key: 'UserDefined4', label: 'User Defined 4' },
	{ key: 'UserDefined5', label: 'User Defined 5' },
	{ key: 'UserDefined6', label: 'User Defined 6' },
	{ key: 'UserDefined7', label: 'User Defined 7' },
	{ key: 'UserDefined8', label: 'User Defined 8' },
	{ key: 'UserDefined9', label: 'User Defined 9' },
	{ key: 'UserDefined10', label: 'User Defined 10' },
];

type ContactFieldsProps = {
	contact: ContactData | null | undefined;
	onChange: (data: Partial<ContactData>) => void;
	canEdit?: boolean;
};

export const ContactFields = ({
	contact,
	onChange,
	canEdit,
}: ContactFieldsProps) => {
	if (!contact) return null;

	return (
		<Container
			component="ContactFields"
			scrollable
			sx={theme => ({ paddingTop: theme.spacing(1) })}
		>
			{fields.map((item) => (
				<TextField
					key={item.key}
					label={item.label}
					aria-label={item.label}
					title={item.label}
					value={(contact as any)[item.key] || ''}
					disabled={!canEdit}
					onChange={(e) =>
						onChange({ ...contact, [item.key]: e.target.value })
					}
					variant="outlined"
					margin="dense"
					sx={theme => ({ margin: theme.spacing(1, 1, 2, 1) })}
					InputLabelProps={{ shrink: true }}
				/>
			))}
		</Container>
	);
};
