import React from 'react';
import { S3FileBrowser } from '../../browser/S3FileBrowser';
import { useS3FileBrowserContext } from '../../browser/S3FileBrowserContext';
import { storage } from '../../browser/StorageProvider';
import { S3Object } from '../../browser/types';
import { isArchiveFile, isMediaFile } from '../../browser/utils';
import { Container, ErrorMessage, Loading } from '../../common';
import { useWindowOpen } from '../../useWindowOpen';

type JobFileListProps = {
	canUploadToJob?: boolean;
	canDelete?: boolean;
	show: boolean;
};

export const JobFileList = ({
	show,
	canUploadToJob,
	canDelete,
}: JobFileListProps) => {
	const { root, busy, error, resetError } = useS3FileBrowserContext();
	const [folder, setFolder] = React.useState(root);
	const open = useWindowOpen();

	const handleOpenFile = async (file: S3Object) => {
		const url = await storage.getDownloadUrl(file.key);
		if (url) {
			open(url);
		}
	};

	React.useEffect(() => {
		setFolder(root);
	}, [root]);

	if (!show) return null;

	return (
		<Container component="FileList" scrollable>
			<ErrorMessage error={error} onRetry={resetError} />
			{busy && <Loading />}
			{!busy && !error && (
				<S3FileBrowser
					view="list"
					disableViewToggle
					disableGalleryButton
					readOnly={!canUploadToJob}
					canDelete={canDelete}
					folder={folder}
					fileFilter={(objects) =>
						objects.filter(
							(x) => x.type !== 'folder' && (!isMediaFile(x.name) || isArchiveFile(x.name))
						)
					}
					onChangeFolder={(f) => setFolder(f.key)}
					onFileOpen={handleOpenFile}
				/>
			)}
		</Container>
	);
};
