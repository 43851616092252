import { Autocomplete as MuiAutocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

export type AutoCompleteOptionOf<T> = {
	label: string;
	value: T;
};

export const errorOption: AutoCompleteOptionOf<null> = {
	label: 'Error Loading Options',
	value: null,
};

type AutoCompleteProps<T> = {
	label?: string;
	placeholder?: string;
	fixed?: boolean;
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
	readOnly?: boolean;
	mixedValue?: boolean;
	autoOpen?: boolean;
	emptyOption?: boolean;
	autoSelect?: boolean;
	delay?: number;
	value?: T | null;
	onChange: (value: T | null) => void;
	options: AutoCompleteOptionOf<T | null>[];
};

export const AutoComplete = <T extends unknown>(
	props: AutoCompleteProps<T>
) => {
	const {
		label,
		placeholder,
		fixed = true,
		required = false,
		disabled = false,
		error = false,
		readOnly = false,
		autoOpen = false,
		autoSelect = fixed ? true : false,
		value,
		onChange,
		options,
	} = props;

	const onOptionSelected = (item: AutoCompleteOptionOf<T | null>) => {
		const option = options.find((x) => x.value === item?.value);
		onChange(option?.value || null);
	};

	return (
		<MuiAutocomplete
			autoHighlight
			fullWidth
			freeSolo={!fixed}
			autoComplete={false}
			disabled={disabled || readOnly}
			autoSelect={autoSelect}
			clearOnEscape
			openOnFocus={autoOpen && !value}
			value={options.find((x) => x.value === value) || null}
			onChange={(_event, option: any) => onOptionSelected(option)}
			options={
				options.length ? options : [{ label: 'loading', value: null }]
			}
			style={{ minWidth: '250px' }}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					placeholder={placeholder || label}
					autoComplete="off"
					variant="outlined"
					size="small"
					margin="dense"
					fullWidth
					required={required}
					disabled={disabled}
					error={error}
					onFocus={(event) => event.target.select()}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						...params.InputProps,
						autoComplete: 'off',
						required: required,
						disabled: disabled,
						readOnly: readOnly,
						error: error,
					}}
				/>
			)}
		/>
	);
};
